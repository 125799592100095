import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ArrayField,
  DateField,
  UrlField,
  RichTextField,
  TextField,
  useRecordContext,
  FunctionField,
  TopToolbar,
  EditButton,
  Button,
  useDataProvider
} from "react-admin";

import { Chip, Grid } from "@material-ui/core";
import { IconButton } from "@mui/material";
import { Done, Close } from "@mui/icons-material";

import NoteList from "../note/list";

import GTextField from "../../common/TextField";
import VideoField from "../../common/VideoField";
import ChipsList from "../../common/ChipsList";
import CandidateAside from "../sidebar";
import UploadResumeForm from "../create/UploadResumeForm";

const GridField = ({ sm, md, source, label, useFlex }) => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Grid item xs={12} sm={sm || 6} md={md || 6}>
      <GTextField source={source} label={label} useFlex={useFlex} />
    </Grid>
  );
};

const Title = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>Candidate {record.id}</span>;
};

const Check = () => (
  <span style={{ display: "flex" }}>
    <Done />
  </span>
);

const Actions = () => {
  const record = useRecordContext();
  const [isFormOpen, setIsFormOpen] = React.useState(false);
  const dataProvider = useDataProvider();

  const updateResumeURL = ({ resumeURL }) => {
    dataProvider.update('candidate', {
      id: record.id,
      data: { resumeURL }
    });
  };

  return (
    <TopToolbar>
      {!isFormOpen &&
        <Button color="primary" onClick={() => { setIsFormOpen(true) }}><>Resume Upload</></Button>
      }
      {isFormOpen && (
        <>
          <UploadResumeForm askEmail={false} email={record.email} setReturnedObject={updateResumeURL} />
          <IconButton color="primary" size="small" onClick={() => { setIsFormOpen(false) }}><Close /></IconButton>
        </>
      )}
      <EditButton />
    </TopToolbar>
  );
}


const CandidateShow = ({ basePath, ...props }) => {
  return (
    <Show
      {...props}
      title={<Title />}
      aside={<CandidateAside />}
      actions={<Actions />}
    >
      <TabbedShowLayout>
        <Tab label="Details">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Basics</h3>
            </Grid>
            <GridField source="firstName" />
            <GridField source="lastName" />
            <GridField source="email" />
            <GridField source="phone" />
            <GridField source="bullhornNumber" label="ID" />
            <GridField source="status" />
          </Grid>
          <h3>Location</h3>
          <Grid container spacing={0}>
            <GridField md={3} source="city" label="City" />
            <GridField md={3} source="state" label="State" />
            <GridField md={3} source="country" label="Country" />
            <GridField md={3} source="zip" label="Zip" />
            <GridField md={8} source="jobSearchStatus" label="Job Search Status" />
            <GridField md={4} source="workAuthorized" label="US Work Authorization" />
          </Grid>
          <ChipsList source="preferredWorkplaceType" label="Preferred Workplace Type" />
          <ChipsList source="preferredLocation" label="Preferred Location" />
          <h3>Details</h3>
          <Grid container spacing={4}>
            <GridField md={4} source="pronouns" label="Pronouns" />
            <GridField md={4} source="completion" label="Profile Completion" />
            <GridField md={4} source="priorityCA" label="CA Priority" />
            <GridField md={4} source="priorityRM" label="RM Priority" />
            <GridField md={4} source="source" label="Source" />
            <GridField md={4} source="sourceDetails" label="Source Details" />
            <GridField md={4} source="reasonNotInterested" label="Reason Not Interested" />
            <GridField md={4} source="reasonNotInterestedDetails" label="Reason Not Interested Details" />
            <GridField md={4} source="venditionInsights" label="Vendition Insights" />
            <GridField md={4} source="videoQuality" label="Video Quality" />
            <GridField md={4} source="candidateType" label="Candidate Type" />
          </Grid>
          <FunctionField
            source="ownerCA" label="CA Owner"
            render={({ ownerCA }) =>
              ownerCA ? `${ownerCA.firstName} ${ownerCA.lastName}` : ''
            }
          />
          <FunctionField
            source="ownerRM" label="RM Owner"
            render={({ ownerRM }) =>
              ownerRM && ownerRM[0] ? `${ownerRM[0]}` : ""
            }
          />
          <ChipsList source="preferredIndustry" label="Preferred Industry" />
          <FunctionField
            source="preferredJobTitle" label="Preferred Job Title"
            render={({ preferredJobTitle }) => {
              if (!preferredJobTitle?.length) return "";

              const titles = preferredJobTitle.map(jobTitle => {
                if (typeof jobTitle === 'string') return jobTitle;
                return jobTitle.title;
              });

              return titles.map((title, index) => (
                <Chip key={index} label={title} style={{ marginLeft: "8px" }} />
              ))
            }}
          />
          <DateField source="timeCreated" label="Time Created" />
          <DateField source="timeUpdated" label="Time Updated" />
          <DateField source="dateAvailable" label="Date Available" />
          <UrlField source="linkedInProfile" label="LinkedIn Profile" target="_blank" />
          <UrlField source="resumeURL" label="Resume Link" target="_blank" />
          <TextField source="comments" label="General Notes" target="_blank" />
        </Tab>
        <Tab label="Notes">
          <NoteList />
        </Tab>
        <Tab label="Work">
          <ArrayField source="employment" label="">
            <Datagrid isRowSelectable={() => false}>
              <FunctionField
                source="current"
                render={({ current }) =>
                  current === "true" ? <Check /> : null
                }
              />
              <TextField source="companyName" label="Company Name" />
              <TextField source="title" />
              <DateField source="startDate" label="Start Date" />
              <DateField source="endDate" label="End Date" />
              <RichTextField source="comments" label="Position Summary" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Education">
          <ArrayField source="education">
            <Datagrid isRowSelectable={() => false}>
              <TextField source="school" />
              <TextField source="status" />
              <DateField source="startDate" />
              <DateField source="endDate" />
              <TextField source="degree" />
              <TextField source="major" />
              <TextField source="minor" />
              <TextField source="gpa" label="GPA" />
              <RichTextField
                source="comments"
                label="Extracurricular Activities"
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="VSAT">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>VSAT Domain Scores</h3>
            </Grid>
            <GridField md={4} source="selfManagementDomainScore" />
            <GridField md={4} source="socialEngagementDomainScore" />
            <GridField md={4} source="cooperationDomainScore" />
            <GridField md={4} source="emotionalResilienceDomainScore" />
            <GridField md={4} source="innovationDomainScore" />
            <Grid item xs={12}>
              <h3>VSAT Candidate Assessment Scores</h3>
            </Grid>
            <GridField md={3} source="sebResults.timeManagement" useFlex />
            <GridField md={3} source="sebResults.organizationalAbility" useFlex />
            <GridField md={3} source="sebResults.responsibilityManagement" useFlex />
            <GridField md={3} source="sebResults.capacityForConsistency" useFlex />
            <GridField md={3} source="sebResults.goalRegulation" useFlex />
            <GridField md={3} source="sebResults.decision-Making" useFlex />
            <GridField md={3} source="sebResults.independence" useFlex />
            <GridField md={3} source="sebResults.leadership" useFlex />
            <GridField md={3} source="sebResults.persuasiveness" useFlex />
            <GridField md={3} source="sebResults.conversationalAbility" useFlex />
            <GridField md={3} source="sebResults.teamwork" useFlex />
            <GridField md={3} source="sebResults.stressRegulation" useFlex />
            <GridField md={3} source="sebResults.optimism" useFlex />
            <GridField md={3} source="sebResults.creativity" useFlex />
            <GridField md={3} source="sebResults.informationProcessing" useFlex />
            <GridField md={3} source="sebResults.adaptability" useFlex />
            <Grid item xs={12}>
              <h3>VSAT Normalized Scores</h3>
            </Grid>
            <GridField md={3} source="normalizedSebResults.timeManagement" useFlex />
            <GridField md={3} source="normalizedSebResults.organizationalAbility" useFlex />
            <GridField md={3} source="normalizedSebResults.responsibilityManagement" useFlex />
            <GridField md={3} source="normalizedSebResults.capacityForConsistency" useFlex />
            <GridField md={3} source="normalizedSebResults.goalRegulation" useFlex />
            <GridField md={3} source="normalizedSebResults.decision-Making" useFlex />
            <GridField md={3} source="normalizedSebResults.independence" useFlex />
            <GridField md={3} source="normalizedSebResults.leadership" useFlex />
            <GridField md={3} source="normalizedSebResults.persuasiveness" useFlex />
            <GridField md={3} source="normalizedSebResults.conversationalAbility" useFlex />
            <GridField md={3} source="normalizedSebResults.teamwork" useFlex />
            <GridField md={3} source="normalizedSebResults.stressRegulation" useFlex />
            <GridField md={3} source="normalizedSebResults.optimism" useFlex />
            <GridField md={3} source="normalizedSebResults.creativity" useFlex />
            <GridField md={3} source="normalizedSebResults.informationProcessing" useFlex />
            <GridField md={3} source="normalizedSebResults.adaptability" useFlex />
          </Grid>
        </Tab>
        <Tab label="Videos">
          <VideoField source="video1" label="Video 1" />
          <VideoField source="video2" label="Video 2" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CandidateShow;
