import styled from "styled-components"

export const Container = styled.div`
  box-sizing: border-box;
  padding: 0.5rem 0.5rem 0 0.7rem;
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 0.25rem;
  width: 100%;
  position: relative;
`;

export const DateInput = styled.input`
  border: 0;
  background-color: transparent;
`;

export const BoxBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
`;

export const DropdownBox = styled.div`
  position: absolute;
  top: 100%;
  z-index: 5;
`;

export const ClearButton = styled.span`
  font-weight: bolder;
  cursor: pointer;
`;
