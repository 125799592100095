import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  SearchInput,
  NumberField,
  DateField,
  RichTextField,
} from "react-admin";

const SubmissionsView = ({ requisitionId }) => {
  const submissionFilters = [<SearchInput source="q" alwaysOn />];
  return (
    <List
      pagination={false}
      filters={submissionFilters}
      resource={`submissions?requisitionId=${requisitionId}&`}
      title={" "}
    >
      <Datagrid
        rowClick={(id) => `/submission/${id}/show`}
        isRowSelectable={() => false}
      >
        <TextField fullWidth source="id" />
        <TextField fullWidth source="status" />
        <TextField
          fullWidth
          source="lastUpdateSource"
          label="Last Update Source"
        />
        <TextField
          fullWidth
          source="requisition.title"
          label="Requisition Title"
        />
        <FunctionField
          source="candidate"
          render={({ candidate }) =>
            candidate
              ? `${candidate.id}: ${candidate.firstName} ${candidate.lastName} - ${candidate.email}`
              : null
          }
        />
        <FunctionField
          source="owners"
          render={({ owners }) =>
            owners?.total
              ? owners.data.map((o) => (
                  <div>{o ? `${o.id}: ${o.firstName} ${o.lastName}` : ""}</div>
                ))
              : null
          }
        />
        <NumberField fullWidth source="requisitionId" label="Requisition ID" />
        <NumberField fullWidth source="sendingUserId" label="Sending User ID" />
        <RichTextField fullWidth source="comments" />
        <DateField fullWidth source="timeDeleted" label="Time Deleted" />
        <DateField fullWidth source="timeCreated" label="Time Created" />
        <DateField fullWidth source="timeUpdated" label="Time Updated" />
      </Datagrid>
    </List>
  );
};

export default SubmissionsView;
