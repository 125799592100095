import { useEffect } from 'react';

const defaultFilters = { "displayedFilters": {}, "filter": {}, "order": "ASC", "page": 1, "perPage": 10, "sort": "id" };

export const useResetFilters = (objectName) => {
  useEffect(() => {
    return () => {
      localStorage.setItem(`RaStore.${objectName}.listParams`, JSON.stringify(defaultFilters));
    }
  }, [objectName])
};