import {
  Create,
  SimpleForm,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  required,
  DateInput,
} from "react-admin";
import { useLocation } from "react-router-dom";
import {
  mapCandidatesToOptions,
  mapClientsToOptions,
  mapEmployeesToOwners,
  mapRequisitionsToOptions,
} from "../../../common/helpers";
import {
  placementApprenticeshipCoach,
  placementApprenticeshipStatus,
  placementEmploymentType,
  placementStatusOpt,
} from "../../common/constants";
import useObject from "../../../hooks/useObject";
import { ChoicesWrapper, SelectInput } from "../../common/customDropdowns";

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const PlacementCreate = () => {
  const location = useLocation();
  const ownersOpt = useObject("employee");
  const clientsOpt = useObject("client");
  const requisitionsOpt = useObject("requisition");

  return (
    <Create
      redirect={"show"}
      transform={({ candidate, requisition, client, ...placement }) => ({
        ...placement,
        candidate: { id: candidate?.bullhornNumber || candidate?.id },
        requisition: { id: requisition?.id },
        dateStart: Date.parse(placement?.dateStart),
        dateEndActual: Date.parse(placement?.dateEndActual),
        datePlacement: Date.parse(placement?.datePlacement),
      })}
    >
      <SimpleForm
        toolbar={<EditToolbar />}
        defaultValues={{
          ...location?.state?.record,
        }}
        warnWhenUnsavedChanges
      >
        <SelectInput
          fullWidth
          source="apprenticeshipCoach"
          choices={placementApprenticeshipCoach}
        />
        <SelectInput
          fullWidth
          source="apprenticeshipStatus"
          choices={placementApprenticeshipStatus}
          isRequired
        />
        <ChoicesWrapper
          source="candidate"
          choices={[]}
          dataMapper={mapCandidatesToOptions}
        >
          <AutocompleteInput fullWidth validate={required()} />
        </ChoicesWrapper>

        <ChoicesWrapper
          source="client"
          choices={clientsOpt}
          dataMapper={mapClientsToOptions}
        >
          <AutocompleteInput fullWidth validate={required()} />
        </ChoicesWrapper>
        <DateInput
          fullWidth
          source="dateEndActual"
          label="Actual End Date"
        />
        <DateInput
          fullWidth
          source="datePlacement"
          label="Date Placement"
          validate={required()}
        />
        <DateInput fullWidth source="dateStart" label="Start Date" />
        <SelectInput
          fullWidth
          source="employmentType"
          choices={placementEmploymentType}
          label="Employment Type"
        />
        <ChoicesWrapper
          source="owner"
          choices={ownersOpt}
          dataMapper={mapEmployeesToOwners}
        >
          <AutocompleteInput fullWidth validate={required()} />
        </ChoicesWrapper>
        <ChoicesWrapper
          source="requisition"
          choices={requisitionsOpt}
          dataMapper={mapRequisitionsToOptions}
        >
          <AutocompleteInput fullWidth validate={required()} />
        </ChoicesWrapper>
        <SelectInput fullWidth source="status" choices={placementStatusOpt} validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default PlacementCreate;