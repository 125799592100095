import {
  DateInput,
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  AutocompleteInput,
  useRecordContext,
  required,
} from "react-admin";
import {
  placementApprenticeshipCoach,
  placementApprenticeshipStatus,
  placementEmploymentType,
  placementStatusOpt,
} from "../../common/constants";
import useObject from "../../../hooks/useObject";
import {
  mapEmployeesToOwners,
  mapClientsToOptions,
  shallowDiff,
  mapCandidatesToOptions,
} from "../../../common/helpers";
import { ChoicesWrapper, SelectInput } from "../../common/customDropdowns";

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const Title = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <span>
      Placement #{record.id}: {record.client.name} - {record.title}
    </span>
  );
};

export const PlacementEdit = (props) => {
  const ownersOpt = useObject("employee");
  return (
    <Edit
      redirect="show"
      title={<Title />}
      transform={(data, { previousData }) => shallowDiff(data, previousData)}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput disabled source="id" />
        <SelectInput
          fullWidth
          source="apprenticeshipCoach"
          choices={placementApprenticeshipCoach}
        />
        <SelectInput
          fullWidth
          source="apprenticeshipStatus"
          choices={placementApprenticeshipStatus}
        />
        <ChoicesWrapper
          source="candidate"
          choices={[]}
          dataMapper={mapCandidatesToOptions}
        >
          <AutocompleteInput disabled fullWidth />
        </ChoicesWrapper>
        <ChoicesWrapper
          source="client"
          choices={[]}
          dataMapper={mapClientsToOptions}
        >
          <AutocompleteInput disabled fullWidth />
        </ChoicesWrapper>
        <DateInput
          fullWidth
          disabled
          source="dateEndActual"
          label="Actual End Date"
        />
        <DateInput
          fullWidth
          disabled
          source="datePlacement"
          label="Date Placement"
        />
        <DateInput fullWidth disabled source="dateStart" label="Start Date" />
        <SelectInput
          fullWidth
          source="employmentType"
          choices={placementEmploymentType}
          label="Employment Type"
        />
        <ChoicesWrapper
          source="owner"
          choices={ownersOpt}
          dataMapper={mapEmployeesToOwners}
        >
          <AutocompleteInput fullWidth validate={required()} />
        </ChoicesWrapper>
        <SelectInput fullWidth source="status" choices={placementStatusOpt} validate={required()} />
        <DateInput
          disabled
          fullWidth
          source="timeCreated"
          label="Time Created"
        />
        <DateInput
          disabled
          fullWidth
          source="timeUpdated"
          label="Time Updated"
        />
      </SimpleForm>
    </Edit>
  );
};

export default PlacementEdit;
