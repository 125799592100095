import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import DataProvider from "../../DataProvider";
import { CompanyContainer, CompanyPage } from "./styled";

const CandidateView = () => {
  const [company, setCompany] = useState()
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const requisitionId = location.state?.id;
  const getCompany = async (companyId) => {
    const { data } = await DataProvider.getOne("client", {id: companyId});
    setCompany(data);
  }

  const handleNavBack = useCallback(() => {
    if (requisitionId) {
      navigate(`/requisition/${requisitionId}/candidate-view`);
    } else {
      navigate(`/client/${id}/show`);
    }
  }, [navigate, requisitionId, id]);

  useEffect(() => {
    getCompany(id);
  }, [id]);

  return <>
    {company && <CompanyContainer>
      <CompanyPage company={company} handleNavBack={handleNavBack} />
    </CompanyContainer>
  }</>;
};

export default CandidateView;
