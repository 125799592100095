import { useRecordContext } from "react-admin";
import { get } from "lodash";
import FieldLabel from "./FieldLabel";
import { Typography, FormControl } from "@material-ui/core";

const sVal = "8px 0 4px";
const TextField = (props) => {
  const record = useRecordContext(props);
  let style = {};

  if (props.useFlex) {
    style = {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
    };
  }

  return (
    <FormControl style={{ margin: sVal, ...style }}>
      <FieldLabel {...props} />
      <div style={{ padding: sVal, marginLeft: props.useFlex ? "10px" : 0 }}>
        <Typography variant="body2">{get(record, props.source)}</Typography>
      </div>
    </FormControl>
  );
};

export default TextField;
