import React, { useEffect, useRef, useState } from "react";
import { useInput, useRecordContext } from 'react-admin';
import * as Sentry from "@sentry/react";
import { LoadContainer, LoadFileArea, PreviewImg, Gallery, Image, AddButton, RemoveButton } from "./styled";
import axios from "axios";
import { getContentType, mimeTypes } from "../helpers";

export const ImageUploader = ({ source }) => {
  const [image, setImage] = useState();
  const [disabledStatus, setDisabledStatus] = useState();
  const fileInputRef = useRef();
  const record = useRecordContext();
  const [tempImgArray, setTempImgArray] = useState(record?.[source] || []);
  const { field: arrayInput } = useInput({ source, defaultValue: record.photos });

  const handleLoadFile = async event => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0]

      const fileReader = new FileReader();

      fileReader.onload = () => {
        setImage(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    } else {
      setImage("")
    }
  }
  const handleAddFile = async event => {
    event.preventDefault();
    setDisabledStatus(true);

    const contentType = getContentType(image);
    const ext = mimeTypes[contentType];

    const token = localStorage.getItem("token");
    const getImgUrls = await axios.get(`${process.env.REACT_APP_API_URL}/client/imagePostURL/${record.id}?suffix=.${ext}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (getImgUrls.status === 200) {
      try {
        const bStr = atob(image.split(",")[1]);
        let n = bStr.length;
        const fileArr = new Uint8Array(n);

        while (n--) {
          fileArr[n] = bStr.charCodeAt(n);
        }

        const putImg = await axios.put(
          getImgUrls.data.putURL,
          new File([fileArr], `${encodeURIComponent(record.id)}picture.${ext}`, { type: ext }),
          { headers: { "Content-Type": contentType } }
        );

        if (putImg.status === 200) {
          setTempImgArray([...tempImgArray, getImgUrls.data.getURL]);
          setImage("");
        }
      } catch (error) {
        if (error instanceof Error) Sentry.captureException(error);
      }
    }
  };

  const removeImgFromArray = (event, index) => {
    event.preventDefault();
    setTempImgArray(tempImgArray.filter((image, idx) => idx !== index ));
  };

  useEffect(() => {
     arrayInput.onChange(tempImgArray);
  }, [tempImgArray]);

  useEffect(() => {
    let disabledStatus = true;
    if (image) {
      disabledStatus = false;
    } else {
      disabledStatus = true;
    }
    setDisabledStatus(disabledStatus);
  }, [image]);

  if(!record) return null;

  return <>
    <LoadContainer>
      <LoadFileArea onClick ={() => fileInputRef.current.click()}>
        <input type="file" onChange={handleLoadFile} ref={fileInputRef} />
        {!image && <><p>Select</p><p>image</p><p>+</p></>}
        {image && <PreviewImg src={image} />}
      </LoadFileArea>
      {image && <AddButton type="button" onClick={handleAddFile} disabled={disabledStatus}> + </AddButton>}
    </LoadContainer>
    <Gallery>
      {tempImgArray.map((image, index) => <Image key={index}>
        <img src={image} alt="" />
        <RemoveButton onClick={event => removeImgFromArray(event, index)}>&times;</RemoveButton>
      </Image>)}
    </Gallery>
  </>;
};
