import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  TabbedForm,
  FormTab,
  TextInput,
  Toolbar,
  SaveButton,
  AutocompleteInput,
  useRecordContext,
  required,
} from "react-admin";
import Grid from '@material-ui/core/Grid';
import {
  employmentTypeOpt,
  requisitionOpt,
  workTypeOpt,
  reqPriorityOpt,
  resumeReviewOpt,
  interviewDurationOpt,
  interviewSchedulingMethodOpt,
} from "../../common/constants";
import RichTextInput from "../../common/RichTextEditor";
import useObject from "../../../hooks/useObject";
import {
  parseDateIntToLocalInt,
  mapEmployeesToOwners,
  mapClientsToOptions,
  shallowDiff,
} from "../../../common/helpers";
import { ChoicesWrapper, SelectInput } from "../../common/customDropdowns";
import * as options from "../../common/constants"
import LocationAutocomplete from "../../common/LocationAutocomplete";

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const Title = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <span>
      Requisition #{record.id}: {record.client.name} - {record.title}
    </span>
  );
};

export const RequisitionEdit = () => {
  const ownersOpt = useObject("employee");
  return (
    <Edit
      redirect="show"
      transform={({ client, interviewDuration, location, ...data }, { previousData }) => ({
        ...shallowDiff(
          {
            ...data,
            ...(client && {
              client: {
                id: client.id,
                name: client.name,
              },
            }),
            ...(interviewDuration ? { interviewDuration } : {}),
            ...(data.workplaceType !== "remote" && location
              ? {
                location: location.label,
                physicalLocationGeo: {
                  lat: location.lat,
                  lon: location.lon,
                }
              }
              : {}),
          },
          previousData
        ),
      })}
      title={<Title />}
    >
      <TabbedForm
        toolbar={<EditToolbar />}
        defaultValues={{ vsat: options.vSatList.reduce((obj, item) => { obj[item] = 3; return obj }, {}), }}
      >
        <FormTab label="General">
          <TextInput disabled source="id" />
          <TextInput fullWidth spellCheck="true" source="title" validate={required()} />
          <SelectInput fullWidth source="status" choices={requisitionOpt} validate={required()} />
          <ChoicesWrapper
            source="client"
            choices={[]}
            dataMapper={mapClientsToOptions}
          >
            <AutocompleteInput disabled fullWidth validate={required()} />
          </ChoicesWrapper>
          <TextInput
            fullWidth
            multiline
            source="description"
            label="Description (Internal)"
          />
          <SelectInput
            fullWidth
            source="interviewSchedulingMethod"
            choices={interviewSchedulingMethodOpt}
            label="Interview Scheduling Method"
          />
          <TextInput fullWidth source="interviewSchedulingLink" />
          <SelectInput
            fullWidth
            source="resumeReview"
            choices={resumeReviewOpt}
          />
          <SelectInput
            fullWidth
            source="interviewDuration"
            choices={interviewDurationOpt}
          />
          <RichTextInput source="about" label="About (External)" validate={required()} />
          <SelectInput
            fullWidth
            source="fullTimeStatus"
            choices={employmentTypeOpt}
            label="Full Time Status"
            validate={required()}
          />
          <RichTextInput source="benefits" />
          <RichTextInput source="interviewProcess" label="Interview Process" validate={required()} />
          <RichTextInput source="teamStructure" label="Team Structure" validate={required()} />
          <RichTextInput
            source="candidateQualities"
            label="Candidate Qualities"
            validate={required()}
          />
          <SelectInput
            fullWidth
            source="workplaceType"
            choices={workTypeOpt}
            label="Workplace Type"
          />
          <LocationAutocomplete source="location" />
          <TextInput fullWidth spellCheck="true" source="interviewCalendars" />
          <TextInput fullWidth multiline source="submissionProcess" />
          <BooleanInput fullWidth source="isOpen" />
          <NumberInput
            fullWidth
            source="numOpenings"
            label="Current Number of Openings"
            validate={required()}
          />
          <TextInput
            fullWidth
            source="numOpeningsInitial"
            label="Initial Number of Openings"
            validate={required()}
          />
          <ChoicesWrapper
            source="owner"
            choices={ownersOpt}
            dataMapper={mapEmployeesToOwners}
          >
            <SelectInput fullWidth validate={required()} />
          </ChoicesWrapper>
          <BooleanInput source="presentable" />
          <SelectInput fullWidth source="priority" choices={reqPriorityOpt} />
          <NumberInput fullWidth source="salaryOTELow" label="Salary OTE Low" validate={required()} />
          <NumberInput fullWidth source="salaryOTEHigh" label="Salary OTE High" validate={required()} />
          <NumberInput fullWidth source="salaryBaseLow" label="Salary Base Low" validate={required()} />
          <NumberInput fullWidth source="salaryBaseHigh" label="Salary Base High" validate={required()} />
          <DateInput
            fullWidth
            source="startDate"
            label="Start Date"
            parse={parseDateIntToLocalInt}
          />
          <DateInput
            fullWidth
            source="dateClosed"
            label="Date Closed"
            parse={parseDateIntToLocalInt}
          />
          <TextInput fullWidth spellCheck="true" source="reasonClosed" />
          <DateInput fullWidth disabled source="timeCreated" label="Time Created" />
          <DateInput fullWidth disabled source="timeUpdated" label="Time Updated" />
        </FormTab>
        <FormTab label="VSAT">
          <Grid container spacing={2} style={{ width: '100%' }}>
            {options.vSatList.map(item =>
              <Grid item md={3}>
                <SelectInput source={`vsat.${item}`} fullWidth choices={options.vSatValuesOpt} label={item} />
              </Grid>)}
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default RequisitionEdit;
