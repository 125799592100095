import axios from "axios";
import AuthProvider from "./AuthProvider";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    config.headers = { Accept: "application/json" };
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      (error.message === "Request failed with status code 401" ||
        error.message === "Network Error") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      await AuthProvider.getToken();
      const token = localStorage.getItem("token");
      if (token) {
        originalRequest.headers["Authorization"] = token;
      } else {
        window.location.href = "/login";
      }
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };