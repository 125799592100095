import styled from "styled-components";

export const Form = styled.form`
  > [type=text] {
    min-width: 40rem;
    display: flex;
  }

  > * {
    margin-left: 1rem;
    flex: 1 1 content;
  }
`;

export const FileUploadContainer = styled.span`
  > [type=file] {
    display: none;
  }
  > button {
    text-decoration: none;
    color: white;
    border-color: transparent;
    background: #1976d2;
    padding: 0.4rem 1rem;
    text-transform: uppercase;
    font-size: small;
    border-radius: 4px;
    border: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.6);

    &:hover {
      background-color: #1565c0;
    }

    &:active {
      box-shadow: none;
    }

    &:visited {
      color: inherit;
    }
  }
`;
