import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import useListByQuery from "../../../hooks/useListByQuery";
import { AsideHeader, AsideTitle, SubmissionsList, SubmissionItem, StyledCard, SmallDetail, SubmissionDetailsContainer, ItemTitle } from "./styled";
import { parseDateIntToLocalInt } from "../../../common/helpers";
import { useRecordContext } from "react-admin";

import AddIcon from "@mui/icons-material/Add";
import BriefcaseIcon from "@mui/icons-material/BusinessCenterOutlined";
import CalendarIcon from "@mui/icons-material/CalendarTodayOutlined";
import SchoolIcon from '@mui/icons-material/School';

const Briefcase = () => <BriefcaseIcon sx={{ fontSize: "1.5rem", marginRight: "0.25rem" }} />
const Calendar = () => <CalendarIcon sx={{ fontSize: "1rem", marginRight: "0.25rem" }} />

const AddSubmissionButton = ({ record }) => (
  <Button
    component={Link}
    to={{ pathname: "/submission/create" }}
    state={{ record: { candidate: record } || {} }}
    startIcon={<AddIcon />}
  >
    Add Submission
  </Button>
);

const AddApprenticeshipButton = ({ record }) => (
  <Button
    component={Link}
    to={{ pathname: "/apprenticeship/create" }}
    state={{ record: { candidate: record } || {} }}
    startIcon={<AddIcon />}
  >
    Add Apprenticeship
  </Button>
);

const toLocalDate = date => {
  return Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(new Date(date));
};


const mapSubmissionsToLI = ({ requisition, status, timeCreated, id, comments, client }) => {
  const d = new Date(parseDateIntToLocalInt(timeCreated));
  const date = `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`;
  return (
    <SubmissionItem>
      <ItemTitle>
        <Briefcase />
        <Button
          component={Link}
          to={{ pathname: `/submission/${id}/show` }}
          sx={{ padding: 0, textTransform: "unset" }}
        >
          {requisition ? requisition.title : ""}
        </Button>
      </ItemTitle>
      <SubmissionDetailsContainer>
        <SmallDetail><Calendar />{date}</SmallDetail>
        <SmallDetail><strong>Status:</strong> {status}</SmallDetail>
      </SubmissionDetailsContainer>
      {client?.name && (
        <div>
          <SmallDetail><strong>Client:</strong> {client.name}</SmallDetail>
        </div>
      )}
      {comments && <SmallDetail><strong>Comments:</strong> {comments}</SmallDetail>}
    </SubmissionItem>
  );
};

const mapApprenticeshipsToLI = ({ id, status, startDate, endDate, completedActions, client }) => {
  return (
    <SubmissionItem>
      <ItemTitle>
        <SchoolIcon sx={{ mr: 1 }} />
        <Button
          component={Link}
          to={{ pathname: `/apprenticeship/${id}/show` }}
          sx={{ padding: 0, textTransform: "unset" }}
        >
          {client.name.toUpperCase()}
        </Button>
      </ItemTitle>
      <Box sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}>
        <Box flex={2}><SmallDetail><strong>Status</strong> <p style={{ marginTop: "5px" }}>{status}</p></SmallDetail></Box>
        <Box flex={2}><SmallDetail><Calendar /><strong>Start Date</strong> <p style={{ marginTop: "5px" }}>{toLocalDate(startDate)}</p></SmallDetail></Box>
        <Box flex={2}><SmallDetail><Calendar /><strong>End Date</strong> <p style={{ marginTop: "5px" }}>{toLocalDate(endDate)}</p></SmallDetail></Box>
      </Box>
      <Box><SmallDetail><strong>Completed Actions: </strong>{completedActions.map(({ type }) => type).join(", ")}</SmallDetail></Box>
    </SubmissionItem>
  );
};

const CandidateAside = props => {
  const record = useRecordContext();
  const submissions = useListByQuery(`submissions?email=${encodeURIComponent(record?.id)}`, !!record?.id);
  const apprenticeships = useListByQuery(`candidate/${record?.email}/apprenticeships`, !!record?.email);

  if (!record) return null;

  return (
    <Box>
      <StyledCard>
        <AsideHeader>
          <AsideTitle>Submissions <span>({submissions.length})</span></AsideTitle>
          <AddSubmissionButton record={record} />
        </AsideHeader>
        <SubmissionsList>
          {submissions.map(mapSubmissionsToLI)}
        </SubmissionsList>
      </StyledCard>
      <br />
      <StyledCard>
        <AsideHeader>
          <AsideTitle>Apprenticeships </AsideTitle>
          <AddApprenticeshipButton record={record} />
        </AsideHeader>
        <SubmissionsList>
          {[...apprenticeships].map(mapApprenticeshipsToLI)}
        </SubmissionsList>
      </StyledCard>
    </Box>
  );
};

export default CandidateAside;
