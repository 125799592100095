import { Datagrid, List, DateField, FunctionField, TextField, TextInput, FilterButton } from 'react-admin';
import DateRangeInput from '../../common/DateRangeInput';
import * as options from "../../common/constants";
import { useResetFilters } from '../../../hooks/useResetFilters';
import { SelectArrayInput } from '../../../components/common/customDropdowns';
import { useLocation } from 'react-router-dom';

const searchBar = [
  <TextInput source="id" />,
  <SelectArrayInput source="apprenticeshipCoach" isFilter choices={options.placementApprenticeshipCoach} />,
  <SelectArrayInput source="apprenticeshipStatus" isFilter choices={options.placementApprenticeshipStatus} />,
  <TextInput source="candidate.email" />,
  <TextInput source="client.name" />,
  <DateRangeInput source="dateEndActual" label="Actual End Date" />,
  <DateRangeInput source="datePlacement" label="Placement Date" />,
  <DateRangeInput source="dateStart" label="Start Date" />,
  <SelectArrayInput source="employmentType" isFilter choices={options.placementEmploymentType} />,
  <TextInput source="lastUpdateSource" label="Last Update Source" />,
  <TextInput source="owner.firstName" />,
  <TextInput source="requisition.title" />,
  <SelectArrayInput source="status" isFilter choices={options.placementStatusOpt} />,
  <DateRangeInput source="timeCreated" label="Date Created" />,
  <DateRangeInput source="timeUpdated" label="Date Updated" />,
];

const PlacementList = () => {
  const { pathname = "" } = useLocation();
  useResetFilters('placement');
  
  return (
    <List filters={searchBar} exporter={false} pagination={false} actions={<><FilterButton /></>}>
      <Datagrid rowClick={(row) => window.open(`${pathname}/${row}/show`)} optimized isRowSelectable={() => false}>
        <TextField fullWidth source="id" />
        <TextField fullWidth source="apprenticeshipCoach" />
        <TextField fullWidth source="apprenticeshipStatus" />
        <FunctionField
          source="candidate"
          render={({ candidate }) => candidate ? `${candidate.id}: ${candidate.firstName} ${candidate.lastName}` : null}
        />
        <FunctionField
          source="client"
          render={({ client }) => client ? `${client.id}: ${client.name}` : null}
        />
        <DateField fullWidth source="dateEndActual" label="Actual End Date" />
        <DateField fullWidth source="datePlacement" label="Placement Date" />
        <DateField fullWidth source="dateStart" label="Start Date" />
        <TextField fullWidth source="employmentType" label="Employment Type" />
        <TextField fullWidth source="lastUpdateSource" label="Last Update Source" />
        <FunctionField
          source="owner"
          render={({ owner }) => owner ? `${owner.id}: ${owner.firstName} ${owner.lastName}` : null}
        />
        <TextField fullWidth source="requisition.title" label="Requisition Title" />
        <TextField fullWidth source="status" />
        <DateField fullWidth source="timeCreated" label="Time Created" />
        <DateField fullWidth source="timeUpdated" label="Time Updated" />
      </Datagrid>
    </List>
  )
};

export default PlacementList
