import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubmissionView from "../SubmissionView";
import {
  Show,
  Tab,
  TextField,
  RichTextField,
  DateField,
  FunctionField,
  useRecordContext,
  TopToolbar,
  Button,
  NumberField,
  EditButton,
  BooleanField,
  TabbedShowLayout,
  ReferenceField,
  CloneButton,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import RatingField from "../../common/RatingField";
import { vSatList } from "../../common/constants";

const Title = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <span>
      Requisition #{record?.id}: {record?.client?.name} - {record?.title}
    </span>
  );
};

const GridRating = ({ sm, md, source, label, useFlex }) => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Grid item xs={12} sm={sm || 6} md={md || 6}>
      <RatingField source={source} label={label} useFlex={useFlex} />
    </Grid>
  );
};

const CandidateShow = ({ basePath, ...props }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const navToCandidateView = useCallback(() => {
    if (id) {
      navigate(`/requisition/${id}/candidate-view`);
    }
  }, [navigate, id]);

  const RequisitionActions = () => (
    <TopToolbar>
      <EditButton />
      <CloneButton />
      <Button color="primary" onClick={navToCandidateView}>
        <>Candidate View</>
      </Button>
    </TopToolbar>
  );

  return (
    <Show {...{ props }} title={<Title />} actions={<RequisitionActions />}>
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="id" />
          <BooleanField source="presentable" />
          <TextField source="title" />
          <TextField source="status" />
          <ReferenceField
            label="Client"
            source="client.id"
            reference="clients"
            link={(record) => `/client/${record.client.id}/show`}
          >
            <FunctionField render={({ id, name }) => `${id} - ${name}`} />
          </ReferenceField>
          <TextField source="location" />
          <TextField
            component="pre"
            source="description"
            label="Description (Internal)"
          />
          <RichTextField source="about" label="About (External)" />
          <TextField source="fullTimeStatus" label="Full Time Status" />
          <RichTextField source="benefits" />
          <RichTextField source="interviewProcess" label="Interview Process" />
          <RichTextField source="teamStructure" label="Team Structure" />
          <RichTextField
            source="candidateQualities"
            label="Candidate Qualities"
          />
          <TextField source="workplaceType" />
          <TextField source="interviewCalendars" label="Interview Calendars" />
          <TextField
            component="pre"
            source="submissionProcess"
            label="Submission Process"
          />
          <BooleanField source="isOpen" />
          <TextField source="numOpenings" />
          <TextField
            source="numOpeningsInitial"
            label="Initial Number of Openings"
          />
          <TextField
            source="interviewSchedulingMethod"
            label="Interview Method"
          />
          <TextField source="interviewSchedulingLink" label="Interview Link" />
          <TextField
            source="interviewDuration"
            label="Interview Duration (minutes)"
          />
          <TextField source="resumeReview" label="Resume Review" />
          <FunctionField
            source="owner"
            render={({ owner }) =>
              `${owner?.id}: ${owner?.firstName} ${owner?.lastName}`
            }
          />
          <TextField source="priority" />
          <NumberField
            source="salaryBaseLow"
            label="Salary Base Low"
            options={{ style: "currency", currency: "USD" }}
          />
          <NumberField
            source="salaryBaseHigh"
            label="Salary Base High"
            options={{ style: "currency", currency: "USD" }}
          />
          <NumberField
            source="salaryOTELow"
            label="Salary OTE Low"
            options={{ style: "currency", currency: "USD" }}
          />
          <NumberField
            source="salaryOTEHigh"
            label="Salary OTE High"
            options={{ style: "currency", currency: "USD" }}
          />
          <DateField source="startDate" />
          <DateField source="dateClosed" />
          <TextField source="reasonClosed" />
          <DateField source="timeCreated" label="Time Created" />
          <DateField source="timeUpdated" label="Time Updated" />
        </Tab>
        <Tab label={"Submissions"}>
          <SubmissionView requisitionId={id} />
        </Tab>
        <Tab label={"VSAT"}>
          <Grid container spacing={2} style={{width: '100%'}}>
            {vSatList.map(item => <GridRating md={3} source={`vsat.${item}`} label={item} useFlex />)}
          </Grid>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CandidateShow;
