import { Datagrid, List, DateField, TextField, TextInput, FunctionField, TopToolbar, FilterButton } from 'react-admin';
import DateRangeInput from '../../common/DateRangeInput';
import * as options from "../../common/constants";
import { useResetFilters } from '../../../hooks/useResetFilters';
import { SelectArrayInput } from '../../../components/common/customDropdowns';
import { useLocation } from 'react-router-dom';

const searchBar = [
  <TextInput source="id" />,
  <TextInput source="candidate.email" />,
  <TextInput source="clientContact.firstName" />,
  <SelectArrayInput source="communicationMethod" isFilter choices={options.commMethodsOpt} />,
  <DateRangeInput source="dateEnd" label="Date End" />,
  <DateRangeInput source="dateBegin" label="Date Begin" />,
  <TextInput source="description" />,
  <TextInput source="owner.firstName" />,
  <TextInput source="requisition.title" />,
  <TextInput source="subject" />,
  <SelectArrayInput source="type" isFilter choices={options.apptTypeOpt} />,
  <TextInput source="location" />,
  <DateRangeInput source="timeCreated" label="Date Created" />,
  <DateRangeInput source="timeUpdated" label="Date Updated" />,
];

const AppointmentList = () => {
  useResetFilters('appointment');
  const {pathname = ''} = useLocation();

  return (
    <List filters={searchBar} exporter={false} pagination={false} actions={<TopToolbar><FilterButton /></TopToolbar>}>
      <Datagrid rowClick={(row) => window.open(`${pathname}/${row}/show`)} optimized isRowSelectable={() => false}>
        <TextField fullWidth source="id" />
        <FunctionField
          source="candidate"
          render={({ candidate }) => candidate ? `${candidate.id}: ${candidate.firstName} ${candidate.lastName}` : null}
        />
        <FunctionField
          source="clientContact"
          render={({ clientContact }) => clientContact ? `${clientContact.id}: ${clientContact.firstName} ${clientContact.lastName}` : null}
        />
        <TextField fullWidth source="communicationMethod" />
        <DateField fullWidth source="dateBegin" showTime />
        <DateField fullWidth source="dateEnd" showTime />
        <TextField fullWidth source="description" />
        <FunctionField
          source="owner"
          render={({ owner }) => owner ? `${owner.id}: ${owner.firstName} ${owner.lastName}` : null}
        />
        <FunctionField
          source="requisition"
          render={({ requisition }) => requisition ? `${requisition.id}: ${requisition.title}` : null}
        />
        <TextField fullWidth source="subject" />
        <TextField fullWidth source="type" />
        <TextField fullWidth source="location" label="Location / Phone / Link" />
        <DateField fullWidth source="timeCreated" />
        <DateField fullWidth source="timeUpdated" />
      </Datagrid>
    </List>
  )
};

export default AppointmentList
