import { useRef, useState } from "react";
import Play from "@mui/icons-material/PlayCircleOutline";
import "./VideoThumbnail.css";

const VideoThumbnail = ({src}) => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paused, setPaused] = useState(true);
  let waiting = false;
  let isFullScr = false;
  let timeout;

  const play = () => { videoRef.current?.play(); setPaused(false) }
  const pause = () => { videoRef.current?.pause(); setPaused(true) }

  const handleClick = () => {
    timeout = setTimeout(() => {
      if (!waiting) {
        if (paused) play();
        else pause();
      }
    }, 500) }
  
  const handleDoubleClick = async () => {
    if (timeout) clearTimeout(timeout);
    waiting = false;
    if (isFullScr) {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
        isFullScr = false;
      }
    } else {
      videoRef.current?.requestFullscreen();
      isFullScr = true;
    }
  }

  return <div className="video-thumbnail__container">
    {src && <video ref={videoRef}
      className="video-thumbnail__video clickable"
      onClick={handleClick}
      onEnded={() => {setPaused(true)}}
      onDoubleClick={handleDoubleClick}
      onWaiting={() => setIsLoading(true)}
      onCanPlay={() => setIsLoading(false)}
      preload="none"
      controlsList="nodownload noremoteplayback">
        <source src={src} />
      </video>}
      {src && paused
      && <Play
      style={{color: 'white', fontSize: '100px'}} 
      className="video-thumbnail__play-button clickable"
      onClick={play} />}
      {!paused && isLoading &&
    <div className="spinner" />}
  </div>
}

export default VideoThumbnail;
