import { Datagrid, List, DateField, FunctionField, NumberField, TextField, RichTextField, TextInput, FilterButton } from 'react-admin';
import DateRangeInput from '../../common/DateRangeInput';
import * as options from "../../common/constants";
import { useResetFilters } from '../../../hooks/useResetFilters';
import { SelectArrayInput } from '../../../components/common/customDropdowns';
import { useLocation } from 'react-router-dom';

const searchBar = [
  <TextInput source="id" />,
  <SelectArrayInput source="status" isFilter choices={options.submisionStatusOpt} />,
  <DateRangeInput source="lastUpdateSource" />,
  <TextInput source="requisitionId" />,
  <TextInput source="requisition.title" />,
  <TextInput source="candidate" />,
  <TextInput source="owners" />,
  <TextInput source="sendingUserId" />,
  <TextInput source="comments" />,
  <DateRangeInput source="timeDeleted" label="Date Deleted" />,
  <DateRangeInput source="timeCreated" label="Date Created" />,
  <DateRangeInput source="timeUpdated" label="Date Updated" />,
];

const SubmissionList = () => {
  const { pathname = "" } = useLocation();
  useResetFilters('submission');

  return (
    <List filters={searchBar} exporter={false} pagination={false} actions={<><FilterButton /></>}>
      <Datagrid rowClick={(row) => window.open(`${pathname}/${row}/show`)} optimized isRowSelectable={() => false}>
        <TextField fullWidth source="id" />
        <TextField fullWidth source="status" />
        <TextField fullWidth source="lastUpdateSource" label="Last Update Source" />
        <NumberField fullWidth source="requisitionId" label="Requisition ID" />
        <TextField fullWidth source="requisition.title" label="Requisition Title" />
        <FunctionField
          source="candidate"
          render={({ candidate }) => candidate ? `${candidate.id}: ${candidate.firstName} ${candidate.lastName} - ${candidate.email}` : null}
        />
        <FunctionField
          source="owners"
          render={({ owners }) => owners?.total ? owners.data.map(o => <div>{`${o.id}: ${o.firstName} ${o.lastName}`}</div>) : null}
        />
        <NumberField fullWidth source="sendingUserId" label="Sending User ID" />
        <RichTextField fullWidth source="comments" />
        <DateField fullWidth source="timeDeleted" label="Time Deleted" />
        <DateField fullWidth source="timeCreated" label="Time Created" />
        <DateField fullWidth source="timeUpdated" label="Time Updated" />
      </Datagrid>
    </List>
  )
};

export default SubmissionList
