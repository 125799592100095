import PlacesAutocomplete from 'react-google-autocomplete';
import { useListContext } from "react-admin";
import FieldLabel from "../FieldLabel";
const MILES_FROM_SEARCH = 20;

const LocationDropdown = ({ source, label }) => {
  const { filterValues, setFilters } = useListContext();
  const handlePlaceSelect = (place) => {
    if (place.geometry.location) {
      setFilters({
        ...filterValues,
        [source]: [{ "lat": place.geometry.location.lat(), "lon": place.geometry.location.lng(), "miles": MILES_FROM_SEARCH }]
      });
    }
  };

  return (
    <div>
      <FieldLabel {...{ source, label }} />
        <PlacesAutocomplete
          onPlaceSelected={handlePlaceSelect}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          debounce={300} />
    </div>
    );
};

export default LocationDropdown;