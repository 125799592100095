import { Edit, DateInput, SimpleForm, TextInput, AutocompleteInput, Toolbar, SaveButton, required } from "react-admin";
import { mapEmployeesToOwners, mapRequisitionsToOptions, mapCandidatesToOptions } from "../../../common/helpers";
import { ChoicesWrapper, SelectInput } from "../../../components/common/customDropdowns"
import * as options from "../../common/constants";

const EditToolbar = props => <Toolbar {...props} ><SaveButton /></Toolbar>;

const SubmissionEdit = () => (
    <Edit transform={(data, {previousData}) => {
        const result = {};
        ["status", "comments", "venditionInsights"].forEach(k => {
          if (data[k] !== previousData[k]) {
            result[k]= data[k];
          }
        })
        return result;
      }}
      redirect="show"
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput disabled source="id" />
        <ChoicesWrapper source="candidate" choices={[]} dataMapper={mapCandidatesToOptions} >
          <AutocompleteInput fullWidth disabled />
        </ChoicesWrapper>
        <ChoicesWrapper source="requisition" choices={[]} dataMapper={mapRequisitionsToOptions} >
          <AutocompleteInput fullWidth disabled />
        </ChoicesWrapper>
        <ChoicesWrapper source="sendingUser" choices={[]} dataMapper={mapEmployeesToOwners}>
          <AutocompleteInput fullWidth disabled label="Sending User" />
        </ChoicesWrapper>
        <SelectInput fullWidth source="status" choices={options.submisionStatusOpt} validate={required()} />
        <TextInput fullWidth multiline disabled source="source" label="Source" />
        <TextInput fullWidth multiline source="comments" />
        <TextInput fullWidth multiline source="venditionInsights" label="Vendition Insights" />
        <DateInput fullWidth disabled source="timeCreated" />
        <DateInput fullWidth disabled source="timeUpdated" />
      </SimpleForm>
    </Edit>
);

export default SubmissionEdit;
