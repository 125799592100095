import { useParams, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import DataProvider from "../../DataProvider";
import { OfferPage } from "./styled";

const OfferView = () => {
  const [offer, setOffer] = useState()
  const [company, setCompany] = useState()
  const { id } = useParams();
  const navigate = useNavigate();
  const getOffer = async (offerId) => {
    const { data: oData } = await DataProvider.getOne("requisition", {id: offerId});
    setOffer(oData);
    const { data: cData } = await DataProvider.getOne("client", {id: oData?.client?.id});
    setCompany(cData);
  }

  useEffect(() => {
    getOffer(id);
  }, [id]);

  const navToCompany = useCallback(() => {
    navigate(`/client/${offer.client.id}/candidate-view`, {state: {id: offer.id}});
  }, [navigate, offer?.client?.id, offer?.id]);

  const navBack = useCallback(() => {
    navigate(`/requisition/${offer.id}/show`);
  }, [navigate, offer?.id]);

  return <>
    {offer && company && <div>
      <OfferPage requisition={{submissions: [], ...offer}} company={company} navigateNext={navToCompany} navigateBack={navBack} isDemo />
    </div>
  }</>;
};

export default OfferView;
