import React from "react";
import { List, Datagrid, TextField, FunctionField, TextInput, BooleanInput, ReferenceField } from 'react-admin';
import { SelectArrayInput } from "../../../components/common/customDropdowns";
import { useResetFilters } from "../../../hooks/useResetFilters";
import * as options from "../../common/constants";
import { useLocation } from 'react-router-dom';

const requisitionFilters = [
  <TextInput source="id" />,
  <TextInput source="title" />,
  <TextInput source="client.name" />,
  <TextInput source="owner.firstName" />,
  <TextInput source="location" />,
  <SelectArrayInput source="employmentType" label="Employment type" isFilter choices={options.employmentTypeOpt} />,
  <TextInput source="numOpenings" label="Number of openings" />,
  <BooleanInput source="isOpen" />,
];

const RequisitionList = props => {
  const { pathname = "" } = useLocation();
  useResetFilters('requisition');

  return (
    <List {...props}
      filters={requisitionFilters}
      exporter={false}
      pagination={false}
    >
      <Datagrid rowClick={(row) => window.open(`${pathname}/${row}/show`)} isRowSelectable={() => false} optimized>
        <TextField source="id" />
        <TextField source="title" />
        <ReferenceField label="Client" source="client.id" reference="clients" link={record => `/client/${record.client.id}/show`}>
          <FunctionField
            render={({ id, name }) => `${id} - ${name}`} />
        </ReferenceField>
        <FunctionField
          source="owner"
          render={({ owner, ownerId }) => owner ? `${ownerId} - ${owner.firstName} ${owner.lastName}` : ""} />
        <TextField source="location" />
        <TextField source="employmentType" />
        <TextField source="numOpenings" />
        <TextField source="isOpen" />
      </Datagrid>
    </List>
  )
};

export default RequisitionList
