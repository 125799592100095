import styled from "styled-components";

export const Button = styled.button`
  background-color: #1565c0;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 1.5rem;
  margin: 0.5rem;
  box-sizing: content-box;
  padding: 0 0.07em 0.15em;
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: 1rem;
`;

export const LoadFileArea = styled.div`
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.6);
  cursor: pointer;

  input[type=file] {
    display: none;
  }

  p {
    margin: 0;
  }
`;

export const PreviewImg = styled.img`
  width: 180px;
  height: 180px;
  object-fit: contain;
  display: inline-block;
  border: 1px solid transparent;
`;

export const Gallery = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -0.5rem;
`;

export const Image = styled.div`
  margin: 0.5rem;
  position: relative;

  img {
    width: 180px;
    height: 180px;
    object-fit: cover;
  }
`;

export const AddButton = styled(Button)`
  align-self: center;

  :disabled {
    background-color: rgba(8, 40, 75, 0.6);
  }
`;

export const RemoveButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
`;
