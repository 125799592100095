import React, { useEffect, useRef, useState } from "react";
//import { useController } from "react-hook-form"
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { removePTag } from "../helpers";

import { Labeled, useInput } from "react-admin";
import { InputWrapper, HelperMessage } from "./styled";

//const options = { options: ["inline", "fontSize", "fontFamily", "list", "textAlign", "link", "embedded", "remove"] }
const options = {
  options: ["inline", "fontFamily", "list", "textAlign", "link"],
  inline: {
    options: ["bold", "italic", "underline", "strikethrough"],
  }
};

const styles = {
  wrapper: {
    width: "100%",
    backgroundColor: "transparent",
  },
  toolbar: {
    backgroundColor: "transparent",
  },
};

const HTMLEditor = props => {
  const {
    defaultValue,
    format,
    parse,
    resource,
    source,
    label,
    validate,
    onBlur,
    onChange,
    ...rest
 } = props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  }  = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    validate,
    onBlur,
    onChange,
    ...rest,
  });
  const prevVal = useRef("");
  let edState = EditorState.createEmpty();
  if (field.value) {
    prevVal.current = field.value;
    edState = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(field.value)));
  }
  const [editorState, setEditorState] = useState(edState);

  const setOnChange = (newState) => {
    setEditorState(newState);
  }

  useEffect(() => {
    if (field.onChange) {
      const newVal = removePTag(draftToHtml(convertToRaw(editorState.getCurrentContent())));

      if (prevVal.current !== newVal) {
        field.onChange(newVal);
        prevVal.current = newVal;
      }
    }
  }, [editorState, field]);

  return (<>
    <InputWrapper error={error}>
      <Labeled label={label} isRequired={isRequired} sx={{...(error && {'& > p': {color: "#d32f2f"}})}}>
        <Editor
          wrapperStyle={styles.wrapper}
          toolbarStyle={styles.toolbar}
          toolbar={options}
          editorState={editorState}
          onEditorStateChange={setOnChange}
          spellCheck
        />
      </Labeled>
    </InputWrapper>
    <HelperMessage error={error}>{(isTouched || isSubmitted) && invalid ? error.message : ''}</HelperMessage>
  </>
  );
}

export default HTMLEditor;
