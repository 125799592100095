import { useRecordContext, Create, TabbedForm, FormTab, TextInput, ArrayInput, SimpleFormIterator, NumberInput, FormDataConsumer, DateInput, BooleanInput, Toolbar, SaveButton, required, email } from "react-admin";
import Grid from "@material-ui/core/Grid";
import {useLocation} from "react-router-dom"

import * as options from "../../common/constants";
import useObject from "../../../hooks/useObject";
import { parseDateIntToLocalInt, mapEmployeesToOwners, mapEmployeesToRM, filterRMEmployees, cleanEmptyData } from "../../../common/helpers";
import { isValidSocialLink } from "../../common/helpers";
import { ChoicesWrapper, SelectInput, AutocompleteArrayInput } from "../../../components/common/customDropdowns";

const Title = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>Candidate {record.id}</span>;
};

const EditToolbar = props => <Toolbar {...props} ><SaveButton /></Toolbar>;

const CandidateCreate = props => {
  const ownersOpt = useObject("employee");
  const location  = useLocation();
  return (
  <Create title={<Title />} {...props} redirect="show"
    transform={data => {
      const {completion, employment, education, ...rest} = data;
      return cleanEmptyData({
        ...rest,
        employment: employment.map(cleanEmptyData),
        education: education.map(cleanEmptyData),
      });
    }}
  >
    <TabbedForm toolbar={<EditToolbar />} defaultValues={location?.state?.record} warnWhenUnsavedChanges >
      <FormTab label='Details'>
        <Grid container spacing={2} style={{width: '100%'}}>
        <Grid item xs={12}><h3>Basics</h3></Grid>
          <Grid item xs={12} sm={6}><TextInput fullWidth source="firstName" validate={required()} /></Grid>
          <Grid item xs={12} sm={6}><TextInput fullWidth source="lastName" validate={required()} /></Grid>
          <Grid item xs={12} sm={6}><TextInput fullWidth source="email" validate={[required(), email()]} /></Grid>
          <Grid item xs={12} sm={6}><TextInput fullWidth source="phone" /></Grid>
          <Grid item xs={12} sm={6}><SelectInput fullWidth source="status" choices={options.statusOpt} validate={required()} /></Grid>
          <Grid item xs={12}><h3>Location</h3></Grid>
          <Grid item xs={12} sm={3}><TextInput fullWidth source="city" /></Grid>
          <Grid item xs={12} sm={3}><TextInput fullWidth source="state" /></Grid>
          <Grid item xs={12} sm={3}><TextInput fullWidth source="country" /></Grid>
          <Grid item xs={12} sm={3}><TextInput source="zip" /></Grid>
          <Grid item xs={12} sm={6}>
            <ChoicesWrapper source="preferredLocation" choices={options.locationOpt} isValueArray>
              <AutocompleteArrayInput fullWidth />
            </ChoicesWrapper>
          </Grid>
          <Grid item xs={12} sm={6}><AutocompleteArrayInput fullWidth source="preferredWorkplaceType" choices={options.workTypeOpt} label="Preferred Workplace Type" /></Grid>
          <Grid item xs={12} sm={6}><TextInput fullWidth source="preferredLocationDetails" /></Grid>
          <Grid item xs={12}><h3>Candidate Details</h3></Grid>
          <Grid item xs={12} sm={6}><SelectInput fullWidth source="jobSearchStatus" choices={options.jobOpt} label="Job Search Status" /></Grid>
          <Grid item xs={12} sm={6}><SelectInput fullWidth source="workAuthorized" choices={options.authorizedOpt} label="US Work Authorization" /></Grid>
          <Grid item xs={12} sm={6}><TextInput fullWidth source="pronouns" /></Grid>
          <Grid item xs={12} sm={6}><SelectInput fullWidth source="priorityCA" choices={options.priorityOpt} label="Priority CA" /></Grid>
          <Grid item xs={12} sm={6}><SelectInput fullWidth source="priorityRM" choices={options.priorityOpt} label="Priority RM" /></Grid>
          <Grid item xs={12} sm={6}><SelectInput fullWidth source="source" choices={options.sourceOpt} validate={required()} /></Grid>
          <Grid item xs={12} sm={6}><TextInput fullWidth source="sourceDetails" label="Source Details" /></Grid>
          <Grid item xs={12} sm={6}><AutocompleteArrayInput fullWidth source="preferredEmploymentType" choices={options.preferredEmpTypeOpt} label="Preferred Employment Type" /></Grid>
          <Grid item xs={12} sm={6}><AutocompleteArrayInput fullWidth source="experienceWithSales" choices={options.salesExperienceOpt} label="Type of Sales Experience" /></Grid>
          <Grid item xs={12} sm={6}><SelectInput fullWidth source="reasonNotInterested" choices={options.reasonNotInterestedOpt} label="Reason Not Interested" /></Grid>
          <Grid item xs={12} sm={6}><TextInput fullWidth source="reasonNotInterestedDetails" label="Reason Not Interested Details" /></Grid>
          <Grid item xs={12}><TextInput fullWidth source="comments" label="General Notes" /></Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput fullWidth
              source="ownerCA"
              label="Owner CA"
              choices={ownersOpt.map(mapEmployeesToOwners)}
              format={o => o?.id ?? ""}
              parse={o => ownersOpt.find(i => i.id === o )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput fullWidth
              source="ownerRM"
              label="Owner RM"
              choices={ownersOpt.filter(filterRMEmployees).map(mapEmployeesToRM)}
              parse={o => [o]}
            />
          </Grid>
          <Grid item xs={12}><TextInput fullWidth multiline source="venditionInsights" label="Vendition Insights" /></Grid>
          <Grid item xs={12} sm={6}><TextInput fullWidth source="linkedInProfile" label="LinkedIn Profile" validate={[isValidSocialLink("linkedin")]} /></Grid>
          <Grid item xs={12} sm={6}><DateInput fullWidth source="dateAvailable" parse={parseDateIntToLocalInt} label="Date Available" /></Grid>
          <Grid item xs={12} sm={6}><SelectInput fullWidth source="videoQuality" label="Video Quality" choices={options.videoQualityOptions} /></Grid>
          <Grid item xs={12} sm={6}><SelectInput fullWidth source="candidateType" choices={options.candidateTypeOpt} label="Candidate Type" /></Grid>
        </Grid>
      </FormTab>
      <FormTab label='Work History'>
        <ArrayInput source="employment">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <Grid container spacing={2} style={{width: '100%'}}>
                    <Grid item xs={12} sm={2}>
                      <TextInput fullWidth source={getSource('companyName')} record={scopedFormData}/>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextInput fullWidth  source={getSource('title')} record={scopedFormData}/>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <BooleanInput source={getSource('current')} record={scopedFormData} format={v => v === "true"} parse={v => "" + v} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <DateInput
                        fullWidth
                        source={getSource('startDate')}
                        record={scopedFormData}
                        label="Start Date"
                        parse={parseDateIntToLocalInt}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <DateInput
                        fullWidth
                        source={getSource('endDate')}
                        record={scopedFormData}
                        label="End Date"
                        parse={parseDateIntToLocalInt}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextInput fullWidth multiline source={getSource('comments')} record={scopedFormData} label="Position Summary" />
                    </Grid>
                  </Grid>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='Education History'>
        <ArrayInput source="education">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <Grid container spacing={2} style={{width: '100%'}}>
                    <Grid item xs={12} sm={6}>
                      <TextInput fullWidth source={getSource('school')} record={scopedFormData} label="School Name" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput fullWidth source={getSource('degree')} record={scopedFormData}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextInput fullWidth source={getSource('major')} record={scopedFormData}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextInput fullWidth source={getSource('minor')} record={scopedFormData}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SelectInput fullWidth source={getSource('status')} record={scopedFormData} choices={options.eduStatusOpt} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NumberInput fullWidth source={getSource('gpa')} record={scopedFormData} parse={v => "" + v} label="GPA" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DateInput
                        fullWidth
                        label="Start Date"
                        source={getSource('startDate')}
                        record={scopedFormData}
                        parse={parseDateIntToLocalInt}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DateInput
                        fullWidth
                        label="End Date"
                        source={getSource('endDate')}
                        record={scopedFormData}
                        parse={parseDateIntToLocalInt}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput fullWidth multiline source={getSource('comments')} record={scopedFormData} label="Extracurricular Activities" />
                    </Grid>
                  </Grid>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
)};

export default CandidateCreate;
