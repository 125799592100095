import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrayField, Button, Datagrid, DateField, TextField, useRecordContext, RichTextField, FunctionField } from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddNoteButton = () => {
  const record = useRecordContext();
  const navigate = useNavigate();

  return (
    <ButtonWrapper>
      <Button
        label="Add Note"
        startIcon={<AddIcon />}
        onClick={() =>
          navigate(`/note/create`, { state: { record: { candidate: record } } })
        }
      />
    </ButtonWrapper>
  );
};

const ListNotes = () => {
  let context = useRecordContext();
  context = {
    ...context,
    notes: (context.notes || []).sort((a, b) => new Date(b.timeCreated) - new Date(a.timeCreated))
  };

  return (
    <div>
      <AddNoteButton/>
      <ArrayField record={context} source="notes">
        <Datagrid isRowSelectable={() => false}>
          <TextField source="action" />
          <FunctionField
            source="author"
            render={({ author }) => {
              let authorObject = {};
              try {
                authorObject = JSON.parse(author);
                return `${authorObject.id}: ${authorObject.firstName} ${authorObject.lastName}`
              } catch (e) {
                authorObject = typeof author === "string" && author;
                return authorObject;
              }
            }}
          />
          <DateField source="timeCreated" label="Time Created"/>
          <RichTextField source="comments"/>
        </Datagrid>
      </ArrayField>
    </div>
  );
};

export default ListNotes;
