export const mapEmployeesToOwners = item => ({ id: item.id, name: item.firstName + " " + item.lastName });
export const mapEmployeesToFilterOwners = item => ({ id: item.firstName, name: item.firstName });
export const mapEmployeesToRM = item => ({ id: item.firstName, name: item.firstName });
export const mapClientsToOptions = item => ({ id: item.id, name: item.id + ": " + item.name });
export const mapRequisitionsToOptions = item => {
  if (item.client) {
    return { id: item.id, name: `${item.id}: ${item.title} - ${item.client.name}` }
  };

  return { id: item.id, name: `${item.id}: ${item.title}` };
};

export const mapCandidatesToOptions = item => ({ id: item.id, name: `${item.email} - ${item.firstName} ${item.lastName}` });
export const mapClientToOptions = item => ({ id: item.id, name: `${item.id} - ${item.name}` });
export const filterRMEmployees = item => item.role === "RM";

export const parseDateIntToLocalInt = v => {
  const dateObj = new Date(v);
  return dateObj.valueOf() + dateObj.getTimezoneOffset() * 60000;
};

export const shallowDiff = (data, prev) => {
  const result = {};

  Object.keys(data).forEach(key => {
    const isDataNull = data[key] === null;
    const isDataObject = typeof data[key] === "object";
    if ((isDataObject && !isDataNull && data[key].id && data[key]?.id !== prev[key]?.id) ||
      (isDataObject && !isDataNull && JSON.stringify(data[key]) !== JSON.stringify(prev[key])) ||
      (!isDataObject && data[key] !== prev[key])) {
      result[key] = data[key];
    }
  })
  return result;
};

export const cleanEmptyData = data => {
  const result = {};
  Object.keys(data).forEach(key => {
    const val = data[key];
    const isValArray = Array.isArray(val);
    if (typeof val === 'object' && !Array.isArray(val) && val != null) {
      const cleanObj = cleanEmptyData(val);
      if (Object.keys(cleanObj).length) {
        result[key] = cleanObj;
      }
    }
    else {
      if (val === 0 || val === false || (!isValArray && val) || (isValArray && val.length)) {
        result[key] = val
      }
    }
  });
  return result;
};

export const filterByColumns = (data, filters) => {
  let filteredData = [...data]
  Object.keys(filters).forEach(field => {
    if (!field.includes("_")) {
      filteredData = filteredData.filter((item) => {
        const notEmptyObject = {
          ...item,
          [field]: item[field] || "",
        };
        const value = JSON.stringify(notEmptyObject[field]).toLowerCase().trim();
        if (Array.isArray(filters[field])) {
          return filters[field].length ? filters[field].some((str) => value.includes(str)) : true;
        } else {
          return filters[field] ? value.includes(filters[field].toLowerCase?.().trim?.()) : true;
        }
      });
    }
  });
  return filteredData;
};

export const filterByIneq = (data, filters) => {
  let filteredData = [...data];
  Object.keys(filters).forEach(field => {
    if (field.includes("_")) {
      const [fieldName, operator] = field.split("_");
      const filterValue = fieldName.includes("time") ? new Date(filters[field]).getTime() : filters[field];
      if (filters[field]) {
        filteredData = filteredData.filter(item => {
          const itemValue = fieldName.includes("time") ? new Date(item[fieldName]).getTime() : item[fieldName];
          if (operator === "lte") {
            return itemValue ? itemValue <= filterValue : true;
          } else {
            return itemValue ? itemValue >= filterValue : true;
          }
        });
      }
    }
  });
  return filteredData;
};

export const containsInequalitiesFilters = (filtersList) => filtersList.reduce((result, item) => result || item.includes("_"), false);

export const titleToCamelCase = str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
};

export const getUTCDate = date => {
  return new Date(Date.UTC(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0,0));
}

export const add24Hours = date => {
  return new Date(date.valueOf() + 86399000)
}

export const choicesMapper = (options, inFilter = false) => {
  let result = [];
  if (Array.isArray(options) && options.length) {
    if (typeof options[0] !== "object") {
      result = options.map(value => ({ id: value, name: value }));
    } else {
      result = [...options];
    }
  }
  if (inFilter) {
    result.push({id: "NOVALUE", name: "No value" });
  }
  return result;
};
