import { CompanyPage as Company } from "@vendition/vendition-frontend";
import styled from "styled-components";

export const CompanyPage = styled(Company)`
  box-sizing: border-box;
`;

export const CompanyContainer = styled.div`
  > div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }
`;
