import React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
} from "react-admin";
import * as options from "../../common/constants";
import { useResetFilters } from "../../../hooks/useResetFilters";
import { SelectArrayInput } from "../../../components/common/customDropdowns";
import { useLocation } from "react-router-dom";
import ChipsField from "../../common/ChipsField";

const clientFilters = [
  <TextInput source="id" />,
  <TextInput source="name" />,
  <TextInput source="location" />,
  <SelectArrayInput source="status" choices={options.clientStatusOpt} />,
];

const ClientList = (props) => {
  useResetFilters("client");
  const { pathname = "" } = useLocation();

  return (
    <List
      {...props}
      filters={clientFilters}
      exporter={false}
      pagination={false}
    >
      <Datagrid
        rowClick={(row) => window.open(`${pathname}/${row}/show`)}
        isRowSelectable={() => false}
        optimized
      >
        <TextField source="id" />
        <TextField source="name" />
        <ChipsField source="location" />
        <TextField source="status" />
      </Datagrid>
    </List>
  );
};

export default ClientList;
