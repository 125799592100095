import {
  Datagrid,
  List,
  TextField,
  FunctionField,
} from "react-admin";

const UsersView = ({ clientId }) => {
  return (
    <List
      pagination={false}
      resource={`/client/${clientId}/user`}
      title={" "}
    >
      <Datagrid
        isRowSelectable={() => false}
      >
        <FunctionField
          source="name"
          render={({ firstName = '', lastName = '' }) => `${firstName} ${lastName}`
          }
        />
        <TextField source="email" />
        <TextField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default UsersView;
