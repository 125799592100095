import React from "react";
import {
  useRecordContext,
  SelectArrayInput as RASelectArrayInput,
  SelectInput as RASelectInput,
  AutocompleteArrayInput as RAAutocompleteArrayInput,
  AutocompleteInput as RAAutocompleteInput,
} from 'react-admin';
import { choicesMapper } from "../../../common/helpers";

// Wrapper to add the information comming from record to options in input choices
export const ChoicesWrapper = ({ source, choices, children, isValueArray, dataMapper = l => ({ id: l, name: l }) }) => {
  const record = useRecordContext();

  const fetchedOptions = [];
  const recordData = record?.[source] ? (isValueArray ? record[source] : [record[source]]) : [];
  const currentOptions = recordData.map(dataMapper);
  choices.map(dataMapper).forEach(o => fetchedOptions.push({ ...o }));
  currentOptions.forEach(o => {
    if (!fetchedOptions.find(f => f.id === o.id)) {
      fetchedOptions.push(o);
    }
  });

  return <>
    {React.Children.map(children, c => React.cloneElement(c, {
      source,
      choices: fetchedOptions,
      ...(!isValueArray && {
        format: o => o?.id,
        parse: o => choices.find(i => i?.id === o)
      }),
    }))}
  </>;
};

export const AutocompleteArrayInput = ({ choices, isFilter = false, ...props }) => {
  return <RAAutocompleteArrayInput choices={choicesMapper(choices, isFilter)} {...props} />;
};

export const AutocompleteInput = ({ choices, isFilter = false, ...props }) => {
  return <RAAutocompleteInput choices={choicesMapper(choices, isFilter)} {...props} />;
};

export const SelectArrayInput = ({ choices, isFilter = false, ...props }) => {
  return <RASelectArrayInput choices={choicesMapper(choices, isFilter)} {...props} />;
};

export const SelectInput = ({ choices, isFilter = false, ...props }) => {
  return <RASelectInput choices={choicesMapper(choices, isFilter)} {...props} />;
};
