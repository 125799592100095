import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import AuthProvider from '../components/AuthProvider';

const useSendingUser = () => {
  const [id, setId] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();

  useEffect(() => {
    AuthProvider.getIdentity()
      .then(async ({ username }) => {
        const { data } = await dataProvider.getList('employee');
        const employee = data.find((item) => item.email.toLowerCase() === username.toLowerCase());

        if (employee) {
          setFullName(`${employee.firstName} ${employee.lastName}`);
          setId(employee.id);
        } else {
          setEmail(username);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataProvider]);

  return { id, fullName, email, loading };
};

export default useSendingUser;
