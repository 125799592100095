import { Edit, DateInput, DateTimeInput, SimpleForm, TextInput, Toolbar, SaveButton, required } from "react-admin";
import { mapEmployeesToOwners, mapRequisitionsToOptions, mapCandidatesToOptions, shallowDiff } from "../../../common/helpers";
import * as options from "../../common/constants";
import useObject from "../../../hooks/useObject";
import { ChoicesWrapper, AutocompleteInput } from "../../common/customDropdowns";

const EditToolbar = props => <Toolbar {...props} ><SaveButton /></Toolbar>;

const AppointmentEdit = () => {
  const candidates = useObject("candidate");
  const owners = useObject("employee");
  const requisitions = useObject("requisition");

  return (
    <Edit
      transform={(data, {previousData}) => shallowDiff({
        dateBegin: Date.parse(data.dateBegin),
        dateEnd: Date.parse(data.dateEnd),
      }, previousData) }
      redirect="show"
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput fullWidth disabled source="id" />
        <ChoicesWrapper source="candidate" choices={candidates} dataMapper={mapCandidatesToOptions}>
          <AutocompleteInput fullWidth source="candidate" validate={required()} />
        </ChoicesWrapper>
        <AutocompleteInput fullWidth source="communicationMethod" choices={options.commMethodsOpt} label="Communication Method" />
        <DateTimeInput fullWidth source="dateBegin" label="Date Begin" validate={required()} />
        <DateTimeInput fullWidth source="dateEnd" label="Date End" validate={required()} />
        <TextInput fullWidth source="description" />
        <ChoicesWrapper source="owner" choices={owners} dataMapper={mapEmployeesToOwners}>
          <AutocompleteInput fullWidth validate={required()} />
        </ChoicesWrapper>
        <ChoicesWrapper source="requisition" choices={requisitions} dataMapper={mapRequisitionsToOptions}>
          <AutocompleteInput fullWidth validate={required()} />
        </ChoicesWrapper>
        <TextInput fullWidth source="subject" />
        <AutocompleteInput fullWidth source="type" choices={options.apptTypeOpt} />
        <TextInput fullWidth source="location" />
        <DateInput fullWidth disabled source="timeCreated" label="Time Created" />
        <DateInput fullWidth disabled source="timeUpdated" label="Time Updated" />
      </SimpleForm>
    </Edit>
)};

export default AppointmentEdit;
