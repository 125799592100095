import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useObjectByParam } from "../../../hooks/useObject";
import { RenderCandidateLink, RenderRequisitionPhrase } from '../../common/helpers'
import {
  Show,
  SimpleShowLayout,
  DateField,
  TextField,
  FunctionField,
  TopToolbar,
  EditButton,
  RichTextField,
  useRecordContext
} from "react-admin";
import ChipsList from "../../common/ChipsList";

const AddAppointmentButton = () => {
  const record = useRecordContext();
  const newAppointment = {
    candidate: record?.candidate,
    requisition: record?.requisition,
  };

  return (
    <Button
      component={Link}
      to={{ pathname: "/appointment/create" }}
      state={{ record: newAppointment }}
      startIcon={<AddIcon />}
      size="small"
    >
      Add Appointment
    </Button>
  );
};

const AddPlacementButton = () => {
  const record = useRecordContext();
  const requisition = useObjectByParam("requisition", {
    id: record?.requisition?.id,
  });

  const newPlacement = {
    candidate: {
      id: record?.candidate?.id,
      firstName: record?.candidate?.firstName,
      lastName: record?.candidate?.lastName,
    },
    requisition: record?.requisition,
    client: requisition?.client,
  };

  return (
    <Button
      component={Link}
      to={{ pathname: "/placement/create" }}
      state={{ record: newPlacement }}
      startIcon={<AddIcon />}
      size="small"
    >
      Create Placement
    </Button>
  );
};

const SubmissionActions = () => (
  <TopToolbar>
    <EditButton />
    <AddAppointmentButton />
    <AddPlacementButton />
  </TopToolbar>
);

const SubmissionShow = () => (
  <Show actions={<SubmissionActions />}>
    <SimpleShowLayout>
      <TextField fullWidth source="id" />
      <TextField fullWidth source="status" />
      <FunctionField label="Requisition" render={RenderRequisitionPhrase} />
      <FunctionField source="candidate" render={RenderCandidateLink} />
      <FunctionField
        source="sendingUser"
        label="Sending User"
        render={({ sendingUser }) =>
          sendingUser
            ? `${sendingUser.id}: ${sendingUser.firstName} ${sendingUser.lastName}`
            : null
        }
      />
      <TextField fullWidth source="comments" />
      <TextField fullWidth source="source" label="Source"/>
      <ChipsList source="reasonsCandidateRejected" label="Reasons Candidate Rejected" />
      <TextField fullWidth source="reasonsCandiateRejectedNotes" label="Reasons Canddiate Rejected Notes"/>
      <RichTextField source="venditionInsights" label="Vendition Insights" />
      <DateField fullWidth source="timeCreated" label="Time Created" />
      <DateField fullWidth source="timeUpdated" label="Time Updated" />
    </SimpleShowLayout>
  </Show>
);

export default SubmissionShow;
