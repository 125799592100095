const AmplifyConfig = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
}

export default AmplifyConfig
