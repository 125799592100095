import { Edit, TabbedForm, FormTab, DateInput, TextInput, Toolbar, SaveButton, required } from "react-admin";
import { shallowDiff } from "../../../common/helpers";
import { empRangesOpt, fundingStageOpt, clientStatusOpt } from "../../common/constants";
import { isValidSocialLink } from "../../common/helpers";
import RichTextInput from "../../common/RichTextEditor";
import { SelectInput } from "../../common/customDropdowns";
import { ImageUploader } from "../../common/imageUploader";

const EditToolbar = props => <Toolbar {...props} ><SaveButton /></Toolbar>;

const ClientEdit = () => (
  <Edit redirect="show"
    transform={(data, {previousData}) => shallowDiff(data, previousData)}
  >
    <TabbedForm toolbar={<EditToolbar />} >
      <FormTab label="General" >
        <TextInput disabled source="id" />
        <TextInput fullWidth source="name" label="Company Name" validate={required()} />
        <TextInput fullWidth spellCheck="true" source="summary" validate={required()} />
        <TextInput fullWidth source="logoURL" label="Logo" validate={required()} />
        <RichTextInput fullWidth source="about" label="About (External)" validate={required()} />
        <TextInput fullWidth source="websiteURL" label="Company Website" />
        <SelectInput fullWidth source="employeeCountRange" label="# of employees" choices={empRangesOpt} validate={required()} />
        <SelectInput fullWidth source="fundingStage" label="Funding Stage" choices={fundingStageOpt} validate={required()} />
        <TextInput fullWidth source="location" label="HQ Location" />
        <TextInput fullWidth spellCheck="true" source="industry" validate={required()} />
        <TextInput fullWidth source="linkedInProfile" label="LinkedIn" validate={[isValidSocialLink("linkedin")]} />
        <TextInput fullWidth source="instagramProfile" label="Instagram" validate={[isValidSocialLink("instagram")]} />
        <TextInput fullWidth source="twitterProfile" label="Twitter" validate={[isValidSocialLink("twitter")]} />
        <TextInput fullWidth spellCheck="true" source="interviewCalendars" label="Interview Calendars" />
        <SelectInput fullWidth source="status" choices={clientStatusOpt} validate={required()} />
        <TextInput fullWidth source="jobBoards" />
        <TextInput fullWidth  spellCheck="true" source="description" label="Description (Internal)" />
        <DateInput fullWidth source="dateOfNextHire" label="Date of Next Hire" />
        <DateInput fullWidth disabled source="timeCreated" label="Time Created" />
        <DateInput fullWidth disabled source="timeUpdated" label="Time Updated" />
      </FormTab>
      <FormTab label="Photos">
        <ImageUploader source="photos" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default ClientEdit;
