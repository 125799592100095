import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";

import { stringify } from "query-string";
import { titleToCamelCase } from "../common/helpers";
import { sortData, prepareData } from "./DataProviderHelper";
import { axiosInstance as axiosI } from "./axiosInstance";
import { parseResume } from "../common/resumeParserHelper";
import { cleanEmptyData } from "../common/helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const DataProvider = {
  getList: async (resource: string, params: GetListParams): Promise<GetListResult> => {
    const { filter } = params ?? {};
    const containsFilters = !!Object.keys(filter || {}).length;
    const paramsString = `${params ? "?" : ""}${containsFilters ? `filter=${encodeURIComponent(JSON.stringify(filter))}` : ""}`
    const url = `${apiUrl}/${containsFilters ? "search/" : ""}${resource}s${paramsString}`;

    try {
      const resp = await axiosI(url);

      const data = prepareData(resp.data, resource, containsFilters);

      if (!data) return { data: [], total: 0 };

      let { order, field: column } = params?.sort || { order: "DESC", field: "id" };
      let filteredData = [...data];

      if (resource.match(/\/client\/[0-9]+\/user/)) {
        filteredData = filteredData.map((user) => ({ ...user, id: user?.sub }));
      }

      if (resource === "candidate") {
        if (column === "id") {
          column = containsFilters ? "_score" : "bullhornNumber";
          order = "DESC";
        };

        filteredData = filteredData.map((item: any) => ({
          ...item,
          bullhornNumber: item?.id,
          id: item?.email,
        }));
      }

      filteredData = filteredData.sort(sortData(column, order));

      return {
        data: filteredData,
        total: data.length,
      };
    } catch {
      return { data: [], total: 0 };
    }
  },

  getOne: async (resource: string, params: GetOneParams): Promise<GetOneResult> => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const response = await axiosI(url);
    const data = typeof response.data === "string" ? JSON.parse(response.data) : response.data;

    if (data) {
      if (resource === "candidate") {
        const sebResults = Object.keys(data.sebResults || {}).map((key) => {
          const attr = titleToCamelCase(key);
          return { [attr]: Math.round(data.sebResults[key].score) }
        }).reduce((acc, cur) => ({ ...acc, ...cur }), {});

        const normalizedSebResults = Object.keys(data.normalizedSebResults || {}).map((key) => {
          const attr = titleToCamelCase(key);
          return { [attr]: Math.round(data.normalizedSebResults[key]) }
        }).reduce((acc, cur) => ({ ...acc, ...cur }), {});

        return {
          data: { ...data, bullhornNumber: data.id, id: data.email, education: data.education, employment: data.employment, sebResults: sebResults, normalizedSebResults: normalizedSebResults },
        };
      } else {
        return { data };
      }
    } else {
      return { data: {} };
    }
  },
  getMany: async (resource: string, params: GetManyParams): Promise<GetManyResult> => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return axiosI(url).then(({ data }) => ({ data }));
  },

  getListReference: async (query: string): Promise<GetListResult> => {
    const url = `${apiUrl}/${query}`;

    return axiosI(url).then(({ headers, data }) => ({
      data,
      total: data?.length ? data.length : 0,
    }));
  },

  getManyReference: async (resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult> => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return axiosI(url).then(({ headers, data }) => ({
      data,
      total: parseInt((headers as any).get("content-range").split("/").pop(), 10),
    }));
  },

  create: async (resource: string, params: CreateParams): Promise<CreateResult> => {
    let cleanedData = cleanEmptyData(params.data);
    return axiosI(`${apiUrl}/${resource}`, {
      method: "PUT",
      data: JSON.stringify(cleanedData),
    }).then(({ data }) => {
      const id = resource === "candidate" ? data?.email : data?.id;
      return {
        data: { ...params, id },
      };
    })
  },

  update: async (resource: string, params: UpdateParams): Promise<UpdateResult> => {
    let { data } = params;
    data.id = params.data.bullhornId;
    delete data.bullhornId;
    let cleanedData = cleanEmptyData(data);

    return axiosI(`${apiUrl}/${resource}/${params.id}`, {
      method: "PATCH",
      data: JSON.stringify(cleanedData),
    }).then(({ data }) => ({ data }));
  },

  updateMany: async (resource: string, params: UpdateManyParams): Promise<UpdateManyResult> => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return axiosI(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PATCH",
      data: JSON.stringify(params),
    }).then(({ data }) => ({ data }));
  },

  delete: async (resource: string, params: DeleteParams): Promise<DeleteResult> =>
    axiosI(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ data }) => ({ data })),

  deleteMany: async (resource: string, params: DeleteManyParams): Promise<DeleteManyResult> => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return axiosI(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      data: JSON.stringify(params),
    }).then(({ data }) => ({ data }));
  },

  getResume: async (email: string, data: string, type: string) => {
    const resume = await axiosI(`${apiUrl}/resume?type=${type}&email=${email}`, {
      method: "POST",
      data,
    });

    const response = {
      parsedCandidate: parseResume(resume.data.parsedCandidate.data),
      fileLocation: resume.data.fileLocation,
    };

    return response;
  },
};

export default DataProvider;
