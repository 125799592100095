const groupByMonth = (startDate, actions) => {
  const grouped = {};

  const monthDiff = (d1, d2) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }


  actions.forEach(action => {
    const month = monthDiff(new Date(startDate), new Date(action.actionDate));
    if (!grouped[month]) {
      grouped[month] = [];
    }
    grouped[month].push(action);
  });

  return grouped;
};


export const CompletedActions = ({ startDate, completedActions = [] }) => {
  const actionsByMonth = groupByMonth(startDate, completedActions);

  return Object.keys(actionsByMonth).map(month => {
    return (
      <div key={month}>
        <h5 style={{ marginBottom: "0" }}>Month {month}</h5>
        <ul>
          {actionsByMonth[month].map(action => (
            <li key={`${action.type}-${action.topic}`} style={{ fontSize: "12px" }}>
              <strong>{action.type}:</strong> {action.topic}
            </li>
          ))}
        </ul>
      </div>
    );
  });
}