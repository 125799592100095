import { useCallback, useEffect, useState } from "react";
import DataProvider from "../components/DataProvider";

export default function useObject(objectName) {
  const [objectList, setObjectList] = useState([]);

  const getObjectList = useCallback(async () => {
    const { data } = await DataProvider.getList(objectName);
    if (data) {
      setObjectList(data);
    }
  }, [objectName]);

  useEffect(() => {
    if (!objectList.length) {
      getObjectList();
    }
  }, [getObjectList, objectList.length]);
  return objectList;
}

export function useObjectByParam(objectName, param) {
  const [object, setObject] = useState({});

  const getObject = useCallback(async () => {
    const { data } = await DataProvider.getOne(objectName, param);
    if (data) {
      setObject(data);
    }
  }, [objectName, param]);

  useEffect(() => {
    if (!object?.client) {
      getObject();
    }
  }, [getObject, object]);

  return object;
}
