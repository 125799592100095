import React from "react";
import { useRecordContext } from "react-admin";
import { StyledChip as Chip } from "./styled";

const ChipsField = ({ source }) => {
  const record = useRecordContext();
  let src = record?.[source];
  src = src ? (Array.isArray(src) ? src : src.split(/[;/]+/)) : [];
  return (
    <div> 
      {src.map((t, i) => <Chip key={source + i} label={t} />)}
    </div>
  )
};

export default ChipsField;
