import { useState } from "react";
import { Button } from "react-admin";
import { Input } from "@mui/material";
import DataProvider from "../../../DataProvider";
import { FileUploadContainer, Form } from "./styled";

const UploadResumeForm = ({ setReturnedObject, askEmail = true, email = "" }) => {
  const [error, setError] = useState("");
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [resumeForm, setResumeForm] = useState({
    email,
    file: { name: "", data: "" },
    type: "",
  });

  const onChangeInput = (event) => {
    setResumeForm({ ...resumeForm, [event.target.name]: event.target.value });
  };

  const onChangeFile = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    setError("");

    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setResumeForm({
        ...resumeForm,
        [event.target.name]: {
          name: file.name,
          data: fileReader?.result?.split(",")[1],
        },
        type:file.name.split(".").pop().toLowerCase(),
      });
    };
  };

  const retrieveDataFromResume = async (event, setReturnedObject) => {
    event.preventDefault();
    setIsFileLoading(true);
    const { email, file, type } = resumeForm;
    try {
      const data = await DataProvider.getResume(window.encodeURIComponent(email), file.data, type);

      setReturnedObject({ ...data.parsedCandidate, email, resumeURL: data.fileLocation.Location });
    } catch (e) {
      setError(e.message === "Network Error" ? "Error" : e.message)
    }
    setIsFileLoading(false);
  };

  return (
    <Form onSubmit={event => retrieveDataFromResume(event, setReturnedObject)}>
      {askEmail && <Input type="text" name="email" placeholder="Email" onChange={onChangeInput} />}
      <FileUploadContainer error={error}>
        <input type="file" name="file" id="file" label="Resume" accept=".pdf,.doc,.docx" onChange={onChangeFile} />
        <button type="button"><label htmlFor="file">{error || resumeForm.file.name || "Upload Resume"}</label></button>
      </FileUploadContainer>
      <Button color="secondary" disabled={!resumeForm.file.data || isFileLoading} size="small" type="submit">
        <>{isFileLoading ? "Loading..." : "Submit"}</>
      </Button>
    </Form>
  );
};
export default UploadResumeForm;
