import { Create, SimpleForm, TextInput, Toolbar, SaveButton, required } from "react-admin";
import { cleanEmptyData } from "../../../common/helpers";
import { empRangesOpt, fundingStageOpt, clientStatusOpt } from "../../common/constants";
import { isValidSocialLink } from "../../common/helpers";
import RichTextInput from "../../common/RichTextEditor";
import { SelectInput } from "../../common/customDropdowns";

const CreateToolbar = props => <Toolbar {...props} ><SaveButton /></Toolbar>;

const ClientCreate = () => (
  <Create redirect="show"
    transform={(data) => cleanEmptyData(data)}
  >
    <SimpleForm toolbar={<CreateToolbar />} >
      <TextInput fullWidth source="name" label="Company Name" validate={required()} />
      <TextInput fullWidth spellCheck="true" source="summary" validate={required()} />
      <TextInput fullWidth source="logoURL" label="Logo" validate={required()} />
      <RichTextInput fullWidth source="about" label="About (External)" validate={required()} />
      <TextInput fullWidth source="websiteURL" label="Company Website" />
      <SelectInput fullWidth source="employeeCountRange" label="# of employees" choices={empRangesOpt} validate={required()} />
      <SelectInput fullWidth source="fundingStage" label="Funding Stage" choices={fundingStageOpt} validate={required()} />
      <TextInput fullWidth source="location" label="HQ Location" validate={required()} />
      <TextInput fullWidth spellCheck="true" source="industry" validate={required()}  />
      <TextInput fullWidth source="linkedInProfile" label="LinkedIn" validate={[isValidSocialLink("linkedin")]} />
      <TextInput fullWidth source="instagramProfile" label="Instagram" validate={[isValidSocialLink("instagram")]} />
      <TextInput fullWidth source="twitterProfile" label="Twitter" validate={[isValidSocialLink("twitter")]} />
      <TextInput fullWidth spellCheck="true" source="interviewCalendars" label="Interview Calendars" />
      <SelectInput fullWidth source="status" choices={clientStatusOpt} validate={required()} />
      <TextInput fullWidth source="jobBoards" />
      <TextInput fullWidth spellCheck="true" source="description" label="Description (Internal)" />
    </SimpleForm>
  </Create>
);

export default ClientCreate;
