import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  SearchInput,
} from "react-admin";

const RequisitionsView = ({ clientId }) => {
  const requisitionFilters = [<SearchInput source="q" alwaysOn />];
  return (
    <List
      pagination={false}
      filters={requisitionFilters}
      resource={`requisitions?clientId=${clientId}&`}
      title={" "}
    >
      <Datagrid
        rowClick={(id) => `/requisition/${id}/show`}
        isRowSelectable={() => false}
      >
        <TextField source="id" />
        <TextField source="title" />
        <FunctionField
          source="owner"
          render={({ owner, ownerId }) =>
            `${ownerId} - ${owner?.firstName} ${owner?.lastName}`
          }
        />
        <TextField source="location" />
        <TextField source="employmentType" />
        <TextField source="numOpenings" />
        <TextField source="isOpen" />
      </Datagrid>
    </List>
  );
};

export default RequisitionsView;
