import { Typography } from '@material-ui/core';
import { useRecordContext } from 'react-admin';
import VideoThumbnail from './VideoThumbnail'

const prompts = {
  empty: '',
  about: 'Tell us about yourself',
  motivation: 'What motivates you?',
  proud: 'Tell us about a time you accomplished something you’re proud of',
  unique: 'What makes your story unique?',
  great: 'What do you think makes a great salesperson?',
  difference: 'What differentiates a good from a great salesperson?'
};


const getPrompt = (sourceURL) => {
  const lLimit = sourceURL.lastIndexOf('_');
  const rLimit = sourceURL.lastIndexOf('.');
  const promptSuffix = lLimit !== -1 && rLimit !== -1 ?
    sourceURL.substring(lLimit + 1, rLimit ) :
    'empty'
  return prompts[promptSuffix] || '';
};

const VideoField = (props) => {
  const record = useRecordContext(props)

  return <>
    <Typography variant='body2' style={{margin: '1rem 0'}}>
      <strong>{`${props.source}: `}</strong>
      {`${getPrompt(record[props.source]||'')}`}
    </Typography>
    <VideoThumbnail src={record[props.source]} />
  </>
};

export default VideoField;
