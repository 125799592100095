import { useCallback, useEffect, useState } from "react";
import { useInput, useRecordContext } from "react-admin";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Autocomplete from "./Autocomplete";

const LocationAutocomplete = ({ source, label }) => {
  const [inputValue, setInputValue] = useState("");

  const record = useRecordContext() ?? {};
  const { field } = useInput({ source });

  useEffect(() => {
    if (record.workplaceType === "remote") {
      setInputValue("");
    } else {
      setInputValue(record.location);
    }
  }, [record.workplaceType, record.location]);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    options: {
      input: "",
      componentRestrictions: { country: "us" },
    }
  });

  const options = placePredictions.map(prediction => ({
    label: prediction.description.replace(/, USA$/, ''),
    value: prediction.place_id
  }));

  const handleInputChange = useCallback((value) => {
    getPlacePredictions({ input: value });
  }, [getPlacePredictions]);

  const handleSelect = useCallback((evt) => {
    const placeId = evt.target.value;
    placesService?.getDetails({ placeId }, (place, status) => {
      const { geometry } = place;
      const label = place.formatted_address?.replace(/, USA$/, '');
      const value = { label, lat: geometry?.location.lat(), lon: geometry?.location.lng() };
      setInputValue(value.label);
      field.onChange(value);
    });
  }, [placesService]);

  if (record.workplaceType === "remote") return null;

  return (
    <Autocomplete
      onChange={handleInputChange}
      onSelect={handleSelect}
      options={options}
      placeholder='Search for a location...'
      initialValue={inputValue}
      label={label ?? "Location"}
    />
  );
};

export default LocationAutocomplete;