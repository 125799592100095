import { BooleanInput, Create, DateInput, NumberInput, TabbedForm, FormTab, TextInput, Toolbar, SaveButton, AutocompleteInput, useRecordContext, LoadingPage, required } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { employmentTypeOpt, requisitionOpt, workTypeOpt, reqPriorityOpt } from "../../common/constants"
import RichTextInput from "../../common/RichTextEditor";
import useObject from '../../../hooks/useObject';
import { parseDateIntToLocalInt, mapEmployeesToOwners, mapClientsToOptions, cleanEmptyData } from '../../../common/helpers';
import useSendingUser from "../../../hooks/useSendingUser";
import { ChoicesWrapper, SelectInput } from '../../common/customDropdowns';
import * as options from "../../common/constants";
import LocationAutocomplete from '../../common/LocationAutocomplete';

const CreateToolbar = props => <Toolbar {...props} ><SaveButton /></Toolbar>;

const Title = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>Requisition #{record.id}: {record.client.name} - {record.title}</span>;
};

export const RequisitionCreate = () => {
  const ownersOpt = useObject("employee");
  const clientsOpt = useObject("client");
  const { id: defaultOwner, loading } = useSendingUser();

  if (loading) return <LoadingPage />;

  return (
    <Create redirect="show"
      transform={({ client, location, ...data }) => cleanEmptyData({
        client: { id: client?.id },
        ...(data.workplaceType !== "remote" && location
          ? {
            location: location.label,
            physicalLocationGeo: {
              lat: location.lat,
              lon: location.lon,
            }
          }
          : {}),
        ...data
      })}
      title={<Title />}>
      <TabbedForm toolbar={<CreateToolbar />} defaultValues={{
        owner: { id: defaultOwner },
        vsat: options.vSatList.reduce((obj, item) => { obj[item] = 3; return obj }, {}),
      }}>
        <FormTab label="General">
          <TextInput fullWidth spellCheck="true" source="title" validate={required()} />
          <SelectInput fullWidth source="status" choices={requisitionOpt} validate={required()} />
          <ChoicesWrapper source="client" choices={clientsOpt} dataMapper={mapClientsToOptions} >
            <AutocompleteInput fullWidth validate={required()} />
          </ChoicesWrapper>
          <TextInput fullWidth multiline source="description" label="Description (Internal)" />
          <RichTextInput source="about" label="About (External)" validate={required()} />
          <SelectInput fullWidth source="fullTimeStatus" choices={employmentTypeOpt} label="Full Time Status" validate={required()} />
          <RichTextInput source="benefits" />
          <RichTextInput source="interviewProcess" label="Interview Process" validate={required()} />
          <RichTextInput source="teamStructure" label="Team Structure" validate={required()} />
          <RichTextInput source="candidateQualities" label="Candidate Qualities" validate={required()} />
          <SelectInput fullWidth source="workplaceType" choices={workTypeOpt} label="Workplace Type" />
          <LocationAutocomplete source="location" />
          <TextInput fullWidth spellCheck="true" source="interviewCalendars" />
          <TextInput fullWidth multiline source="submissionProcess" />
          <BooleanInput fullWidth source="isOpen" />
          <NumberInput fullWidth source="numOpenings" label="Current Number of Openings" validate={required()} />
          <TextInput fullWidth source="numOpeningsInitial" label="Initial Number of Openings" validate={required()} />
          <ChoicesWrapper source="owner" choices={ownersOpt} dataMapper={mapEmployeesToOwners}>
            <SelectInput fullWidth validate={required()} />
          </ChoicesWrapper>
          <BooleanInput source="presentable" />
          <SelectInput fullWidth source="priority" choices={reqPriorityOpt} emptyValue={0} />
          <NumberInput fullWidth source="salaryOTELow" label="Salary OTE Low" validate={required()} />
          <NumberInput fullWidth source="salaryOTEHigh" label="Salary OTE High" validate={required()} />
          <NumberInput fullWidth source="salaryBaseLow" label="Salary Base Low" validate={required()} />
          <NumberInput fullWidth source="salaryBaseHigh" label="Salary Base High" validate={required()} />
          <DateInput fullWidth source="startDate" label="Start Date" parse={parseDateIntToLocalInt} />
          <DateInput fullWidth source="dateClosed" label="Date Closed" parse={parseDateIntToLocalInt} />
          <TextInput fullWidth spellCheck="true" source="reasonClosed" label="Reason Closed" />
        </FormTab>
        <FormTab label="VSAT">
          <Grid container spacing={2} style={{ width: '100%' }}>
            {options.vSatList.map(item =>
              <Grid item md={3}>
                <SelectInput source={`vsat.${item}`} fullWidth choices={options.vSatValuesOpt} label={item} />
              </Grid>)}
          </Grid>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default RequisitionCreate;
