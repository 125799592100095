import { Create, SimpleForm, AutocompleteInput, Toolbar, SaveButton, TextInput, required } from "react-admin";
import { useLocation } from "react-router-dom";
import { mapRequisitionsToOptions, mapCandidatesToOptions } from "../../../common/helpers";
import * as options from "../../common/constants";
import useObject from "../../../hooks/useObject";
import useSendingUser from "../../../hooks/useSendingUser";
import { ChoicesWrapper, SelectInput } from "../../common/customDropdowns";

const EditToolbar = props => <Toolbar {...props} ><SaveButton /></Toolbar>;

const SubmissionCreate = () => {
  const requisitions = useObject("requisition");
  const location = useLocation();
  const sendingUser = useSendingUser();

  return (
  <Create
    transform={({candidate, requisition, ...data}) => {
      return ({
        candidate: { id: candidate?.bullhornNumber },
        requisition: { id: requisition?.id },
        sendingUser: sendingUser.id ? { id: sendingUser.id } : { email: sendingUser.email },
        ...data
      })}
    }
    redirect="show"
  >
    <SimpleForm
      toolbar={<EditToolbar />}
      defaultValues={{
        status: "Internally Submitted",
        ...location?.state?.record,
      }}
      warnWhenUnsavedChanges
    >
      <ChoicesWrapper source="candidate" choices={[]} dataMapper={mapCandidatesToOptions} >
        <AutocompleteInput fullWidth disabled />
      </ChoicesWrapper>
      <ChoicesWrapper source="requisition" choices={requisitions} dataMapper={mapRequisitionsToOptions} >
        <AutocompleteInput fullWidth validate={required()} />
      </ChoicesWrapper>
      <SelectInput fullWidth source="status" choices={options.submisionStatusOpt} validate={required()} />
      <TextInput fullWidth multiline source="comments" />
      <TextInput fullWidth disabled multiline source="source" label="Source" defaultValue="admin_app" />
      <TextInput fullWidth multiline source="venditionInsights" label="Vendition Insights" />
    </SimpleForm>
  </Create>
)};

export default SubmissionCreate;
