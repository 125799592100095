const FieldLabel = (props) => {
  const autoLabel = props.source
    .split('.')
    .pop()
    .match(/^[a-z]+|[A-Z][a-z]*/g)
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");

  const styles = {
    label: {
      fontSize: "0.75rem",
      color: "rgba(0,0,0,0.54)",
      display: "inline-block",
    },
  };

  return (
    <>
      <label style={styles.label}>{props.label || autoLabel}</label>
    </>
  );
};

export default FieldLabel;
