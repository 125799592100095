import React, { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Show,
  TabbedShowLayout,
  Tab,
  ImageField,
  FunctionField,
  UrlField,
  RichTextField,
  TextField,
  useRecordContext,
  TopToolbar,
  Button,
  EditButton,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";

import ChipsField from "../../common/ChipsField";
import { Photo, PhotosContainer } from "./styled";
import Requisitions from "../requisitions";
import Users from "../users";
import ApprenticeshipsView from "./Apprentieceships";

const Title = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <span>
      Client {record.id}: {record.name}
    </span>
  );
};

const ClientShow = ({ basePath, ...props }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const navToCandidateView = useCallback(() => {
    if (id) {
      navigate(`/client/${id}/candidate-view`);
    }
  }, [navigate, id]);

  const AddUserButton = () => {
    const record = useRecordContext();
    const navigate = useNavigate();

    return (
      <Button
        label="Add User"
        startIcon={<AddIcon />}
        onClick={() =>
          navigate(`/user/create`, { state: { record: { client: record } } })
        }
      />
    );
  };

  const ClientActions = () => (
    <TopToolbar>
      <EditButton />
      <AddUserButton />
      <Button color="primary" onClick={navToCandidateView}>
        <>Candidate View</>
      </Button>
    </TopToolbar>
  );

  return (
    <Show {...props} title={<Title />} actions={<ClientActions />}>
      <TabbedShowLayout>
        <Tab label="Details">
          <ImageField source="logoURL" label="Logo" />
          <TextField source="id" />
          <TextField source="name" />
          <UrlField source="websiteURL" label="Website URL" />
          <UrlField source="instagramProfile" label="Instagram Profile" />
          <UrlField source="twitterProfile" label="Twitter Profile" />
          <UrlField source="linkedInProfile" label="LinkedIn Profile" />
          <TextField source="employeeCountRange" />
          <ChipsField source="location" />
          <RichTextField source="summary" />
          <RichTextField source="about" />
          <RichTextField source="description" />
          <TextField source="employeeCountRange" label="Employee Count Range" />
          <TextField source="industry" />
          <TextField source="fundingStage" />
          <TextField source="jobBoards" label="Job Boards" />
          <TextField source="dateOfNextHire" label="Date of Next Hire" />
        </Tab>
        <Tab label="Photos">
          <FunctionField
            source="photos"
            render={({ photos = [] }) => (
              <PhotosContainer>
                {photos.map((item) => (
                  <Photo key={item}><img src={item} alt="" /></Photo>
                ))}
              </PhotosContainer>
            )}
          />
        </Tab>
        <Tab label="Requisitions">
          <Requisitions clientId={id} />
        </Tab>
        <Tab label="Users">
          <Users clientId={id} />
        </Tab>
        <Tab label="Apprenticeships">
          <ApprenticeshipsView clientId={id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ClientShow;
