
import styled, { css } from "styled-components";

export const InputWrapper = styled.div`
  box-sizing: border-box;
  padding: 0.5rem 0.5rem 0 0.7rem;
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
  width: 100%;
  ${ props => props.error && css`
    border-bottom: 2px solid #d32f2f;
  `}
`  
export const HelperMessage = styled.p`
  font-size: 0.75rem;
  margin-top: calc(2px - 1rem);
  margin-left: 0.85rem;
  letter-spacing: 0.03333em;
  ${ props => props.error && css`
    color: #d32f2f;
  `}
`;
