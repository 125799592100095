import { useRecordContext } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import styled from "styled-components"

const StyledChip = styled(Chip)`
  margin: 0.3rem;
`

const ChipsList = (props) => {
  const record = useRecordContext(props);
  if (!record) return null;
  return (
    <div>
      {(record[props.source] || []).map((t, i)=> <StyledChip key={(props.source+i)} label={t} />) || []}
    </div>
  );
};

export default ChipsList
