import { Show, SimpleShowLayout, DateField, TextField, TopToolbar, EditButton, RichTextField, FunctionField } from 'react-admin';
import { RenderCandidateLink, RenderRequisitionPhrase } from '../../common/helpers';

const SubmissionActions = () => (
  <TopToolbar>
      <EditButton />
  </TopToolbar>
);

const SubmissionShow = () => (
  <Show actions={<SubmissionActions />}>
    <SimpleShowLayout>
      <TextField fullWidth source="id" />
      <FunctionField
        source="candidate"
        render={RenderCandidateLink}
      />
      <FunctionField
        source="clientContact"
        render={({ clientContact }) => clientContact ? `${clientContact.id}: ${clientContact.firstName} ${clientContact.lastName}` : null}
      />
      <TextField fullWidth source="communicationMethod" />
      <DateField fullWidth source="dateBegin" showTime />
      <DateField fullWidth source="dateEnd" showTime />
      <RichTextField fullWidth source="description" />
      <FunctionField
        source="owner"
        render={({ owner }) => owner ? `${owner.id}: ${owner.firstName} ${owner.lastName}` : null}
      />
      <FunctionField
        source="requisition"
        render={RenderRequisitionPhrase}
      />
      <TextField fullWidth source="subject" />
      <TextField fullWidth source="type" />
      <TextField fullWidth source="location" />
      <DateField fullWidth source="timeCreated" />
      <DateField fullWidth source="timeUpdated" />
    </SimpleShowLayout>
  </Show>
);

export default SubmissionShow;