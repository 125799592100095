import { useState } from 'react';
import { useRedirect, useNotify, Notification } from 'react-admin';

import { Avatar, Card, CardActions, Button, TextField, FormControl } from '@material-ui/core';
import LockIcon from '@mui/icons-material/Lock';

import AuthProvider from './components/AuthProvider';
import * as Sentry from "@sentry/react";
import "./Login.css"

const Login = () => {
  const [cUser, setCUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({username: '', password: '', code: ''});
  const [inputError, setInputError] = useState({username: false, password: false, code: false});
  const [status, setStatus] = useState('login');
  const notify = useNotify();
  const redirect = useRedirect();
  
  const handleLogin = async event => {
    event.preventDefault();
    setIsLoading(true);
    Sentry.setUser({ username: form.username });
    try {
      const cognitoUser = await AuthProvider.login({ username: form.username, password: form.password })
      setCUser(cognitoUser);
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setForm({...form, password: '' });
        setStatus('chgpwd');
      } else {
        AuthProvider.getToken();
        redirect('/');
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  }

  const handleChgPwd = async event => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const cognitoUser = await AuthProvider.changePassword({cUser, password: form.password})
      if (cognitoUser) {
        redirect('/');
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  }

  const handleResetPwd = async event => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const data = await AuthProvider.resetPassword(form);
      if (data) {
        redirect('/');
      }
    } catch (error) {
      notify(error.message)
    }
    setIsLoading(false);
  }

  const handleChange = event => {
    const {name, value} = event.target;
    setInputError({...inputError, [name]: false})
    setForm({...form, [name]: value });
  }
  
  const setError = event => {
    let isError = false;
    const {name, value} = event.target;
    if (!value && event.type === 'blur') isError = true;
    setInputError({...inputError, [name]: isError});
  }

  const toggleStatus = () => {
    const newStatus = status === 'reset' ? 'login' : 'reset';
    setStatus(newStatus);
  }

  return (
    <div className='login'>
      <Card>
        <div className='header'><Avatar><LockIcon /></Avatar></div>
          {status === 'chgpwd' && <p>Please change your password to continue signing in.</p>}
          <form onSubmit={status === 'login' ? handleLogin : (status === 'reset' ? handleResetPwd : handleChgPwd)}>
            <div className='container'>
              {(status === 'login' ||
                status === 'reset') && <FormControl fullWidth>
                <TextField
                  id="username"
                  name="username"
                  label="User Name"
                  required
                  onChange={handleChange}
                  onBlur={setError}
                  onFocus={setError}
                  value={form.username}
                  helperText={inputError.username ? 'Required':''}
                  error={inputError.username}
                />
              </FormControl>}
              <FormControl fullWidth>
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  label="Password"
                  required
                  onChange={handleChange}
                  onBlur={setError}
                  onFocus={setError}
                  value={form.password}
                  helperText={inputError.password ? 'Required':''}
                  error={inputError.password}
                />
              </FormControl>
              {status === 'reset' && <FormControl fullWidth>
                <TextField
                  id="code"
                  name="code"
                  type="code"
                  label="Code"
                  required
                  onChange={handleChange}
                  onBlur={setError}
                  onFocus={setError}
                  value={form.code}
                  helperText={inputError.code ? 'Required':''}
                  error={inputError.code}
                />
              </FormControl>}
            </div>
            <CardActions>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
                disabled={isLoading}
              >
                Submit
              </Button>
            </CardActions>
          </form>
      </Card>
      <Button className="secondary" onClick={toggleStatus}>
        {status === 'login' ? 'Reset password' : 'Log in'}
      </Button>
      <Notification />
    </div>
  )
}

export default Login
