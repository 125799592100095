import {
  Create,
  SimpleForm,
  AutocompleteInput,
  required,
  DateInput,
  TextInput,
  SimpleFormIterator,
  FormDataConsumer,
  ArrayInput,
} from "react-admin";
import {
  mapCandidatesToOptions,
  mapClientsToOptions,
  parseDateIntToLocalInt,
} from "../../../common/helpers";
import useObject from "../../../hooks/useObject";
import { ChoicesWrapper, SelectInput } from "../../common/customDropdowns";
import { Box } from "@material-ui/core";
import { completedActionTypes } from "../../common/constants";

const ApprenticeshipCreate = () => {
  const clientsOpt = useObject("client");

  return (
    <Create
      redirect={"show"}
      transform={({ candidate, requisition, client, ...data }) => {
        return {
          ...data,
          candidate: { id: candidate?.bullhornNumber || candidate?.id },
          client: { id: client?.id },
          candidateId: candidate?.bullhornNumber || candidate?.id,
          clientId: client?.id,
        };
      }}
    >
      <SimpleForm>
        <ChoicesWrapper source="candidate" choices={[]} dataMapper={mapCandidatesToOptions}>
          <AutocompleteInput fullWidth source="candidate" validate={required()} disabled />
        </ChoicesWrapper>
        <ChoicesWrapper source="client" choices={clientsOpt} dataMapper={mapClientsToOptions}>
          <AutocompleteInput fullWidth source="client" validate={required()} />
        </ChoicesWrapper>
        <SelectInput source="status" fullWidth choices={[
          { id: "Active", name: "Active" },
          { id: "Inactive", name: "Inactive" },
        ]} />
        <DateInput fullWidth source="startDate" validate={required()} parse={parseDateIntToLocalInt} />
        <DateInput fullWidth source="endDate" validate={required()} parse={parseDateIntToLocalInt} />
        <ArrayInput source="completedActions">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <SelectInput source={getSource('type')}
                      choices={completedActionTypes.map(type => ({ id: type, name: type }))}
                      style={{ marginRight: "20px", minWidth: "200px" }}
                    />
                    <TextInput source={getSource('topic')} record={scopedFormData} style={{ marginRight: "20px", width: "400px" }} />
                    <DateInput
                      source={getSource('actionDate')}
                      record={scopedFormData}
                      label="Completed Action Date"
                      parse={parseDateIntToLocalInt}
                    />
                  </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default ApprenticeshipCreate;