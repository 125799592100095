export const getEducationStatus = (education) => {
  if (education.dates?.completionDate) return "Graduated";
  if (education.dates?.isCurrent) return "In Progress";
  return "Incomplete";
};

export const mapEducationValues = (education) => {
  if (!education) return [];

  return education.map((ed) => {
    return {
      id: ed.id,
      school: ed.organization ?? '',
      status: getEducationStatus(ed),
      startDate: ed.dates?.startDate ?? '',
      endDate: ed.dates?.completionDate ?? '',
      degree: ed.accreditation?.educationLevel ?? '',
      major: ed.accreditation?.education ?? '',
      minor: '',
      gpa: ed.grade?.value ?? '',
      comments: '',
    };
  });
};

export const mapWorkHistory = (workHistory) => {
  if (!workHistory) return [];

  return workHistory.map((work) => {
    return {
      id: work.id,
      companyName: work.organization ?? '',
      title: work.jobTitle ?? '',
      current: work.dates?.isCurrent ? "true" : "false",
      startDate: work.dates?.startDate ?? '',
      endDate: work.dates?.endDate ?? '',
      comments: work.jobDescription ?? '',
    };
  });
};

export const parseResume = (parsedCandidate) => {
  const candidate = {};
  candidate.firstName = parsedCandidate.name?.first ?? "";
  candidate.lastName = parsedCandidate.name?.last ?? "";
  candidate.email = parsedCandidate.emails?.[0] ?? "";
  candidate.phone = parsedCandidate.phoneNumbers?.[0] ?? "";
  candidate.education = mapEducationValues(parsedCandidate.education);
  candidate.employment = mapWorkHistory(parsedCandidate.workExperience);

  return candidate;
};