export const prepareData = (data, resource, containsFilters) => {
  if (resource === "employee") {
    return data?.employees;
  }

  if (containsFilters) {
    return data?.hits.map((item) => ({ ...item._source, _score: item._score }))
  }

  return data.map(item => ({ ...item, _score: "-" }));
};

export const sortData = (sortColumn, sortOrder) => (a, b) => {
  const x = a[sortColumn];
  const y = b[sortColumn];

  if (x instanceof Date && y instanceof Date) {
    return sortOrder === "DESC" ? y.getTime() - x.getTime() : x.getTime() - y.getTime();
  }

  if (typeof x === "string" || typeof y === "string") {
    if (!x) return sortOrder === "DESC" ? 1 : -1;
    if (!y) return sortOrder === "DESC" ? -1 : 1;
    
    return sortOrder === "DESC" ? y.localeCompare(x) : x.localeCompare(y);
  }

  return sortOrder === "DESC" ? y - x : x - y;
};