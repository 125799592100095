import { useCallback, useEffect, useState } from "react";
import DataProvider from "../components/DataProvider"

export default function useListByQuery(query, enabled = true) {
  const [recordList, setRecordList] = useState([]);

  const getRecordList = useCallback(async () => {
    if (query && enabled) {
      const { data } = await DataProvider.getListReference(query);
      if (data) {
        setRecordList(data);
      }
    }
  }, [query, enabled]);
  
  useEffect(() => {
    if (!recordList.length) {
      getRecordList();
    }
  }, [getRecordList, recordList.length]);
  return recordList;
};
