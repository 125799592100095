import { Auth } from 'aws-amplify'

const AuthProvider = {
  // authentication
  login: ({username, password}) => Auth.signIn(username, password),
  checkError: error => {return Promise.resolve()},
  checkAuth: async () => {
    const user = await Auth.currentAuthenticatedUser();
      if (!user) {
        return Promise.reject("You need to sign in to access that page.");
      }
      return Promise.resolve(user);
  },
  logout: () => Auth.signOut(),
  getIdentity: () => Auth.currentUserInfo(),
  changePassword: ({cUser, password}) => Auth.completeNewPassword(cUser, password),
  resetPassword: ({username, password, code}) => Auth.forgotPasswordSubmit(username, code, password),
  getToken: async () => {
    delete localStorage.token;
    const session = await Auth.currentSession();
    if (session) {
      localStorage.setItem("token", session.idToken.jwtToken);
    }
  },
  // authorization
  getPermissions: params => Promise.resolve(),
}

export default AuthProvider
