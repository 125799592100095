import { Card } from "@mui/material";
import styled from "styled-components"

export const StyledCard = styled(Card)`
  width: 30vw;
  margin: 0 0 0 1em;
  padding: 1rem;
  box-sizing: content-box;
`;

export const AsideHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AsideTitle = styled.span`
  font-size: 1.25rem;
`;
export const SubmissionsList = styled.ul`
  list-style: none;
  padding: 0;
`;
export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
`;
export const SubmissionItem = styled.li`
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0.5rem 0;
  > div > a {
    font-size: 1rem;
  }
`;

export const SubmissionDetailsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem;
`;

export const SmallDetail = styled.span`
  font-size: small;
`;