import { Edit, DateInput, SimpleForm, TextInput, Toolbar, SaveButton, required, ArrayInput, SimpleFormIterator, FormDataConsumer, SelectInput } from "react-admin";
import { mapCandidatesToOptions, mapClientsToOptions, parseDateIntToLocalInt } from "../../../common/helpers";
import { ChoicesWrapper, AutocompleteInput } from "../../common/customDropdowns";
import { Box } from "@material-ui/core";
import { completedActionTypes } from "../../common/constants";

const EditToolbar = props => <Toolbar {...props} ><SaveButton /></Toolbar>;

const ApprenticeshipEdit = () => {
  return (
    <Edit
      redirect="show"
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <ChoicesWrapper source="candidate" choices={[]} dataMapper={mapCandidatesToOptions}>
          <AutocompleteInput fullWidth source="candidate" validate={required()} disabled />
        </ChoicesWrapper>
        <ChoicesWrapper source="client" choices={[]} dataMapper={mapClientsToOptions}>
          <AutocompleteInput fullWidth source="client" validate={required()} disabled />
        </ChoicesWrapper>
        <SelectInput source="status" fullWidth choices={[
          { id: "Active", name: "Active" },
          { id: "Inactive", name: "Inactive" },
        ]} />
        <DateInput fullWidth source="startDate" validate={required()} parse={parseDateIntToLocalInt} />
        <DateInput fullWidth source="endDate" validate={required()} parse={parseDateIntToLocalInt} />
        <ArrayInput source="completedActions">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <SelectInput source={getSource('type')}
                      choices={completedActionTypes.map(type => ({ id: type, name: type }))}
                      style={{ marginRight: "20px", minWidth: "200px"}}
                    />
                    <TextInput source={getSource('topic')} record={scopedFormData} style={{ marginRight: "20px", width: "400px" }} />
                    <DateInput
                      source={getSource('actionDate')}
                      record={scopedFormData}
                      label="Completed Action Date"
                      parse={parseDateIntToLocalInt}
                    />
                  </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
};

export default ApprenticeshipEdit;
