import React, { useState } from "react";

import {
  Button, List, Datagrid, TextField, TextInput, EmailField,
  TopToolbar, CreateButton, FilterButton, 
} from 'react-admin';
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";

import * as options from "../../common/constants";
import UploadForm from "../create/UploadResumeForm";
import { filterRMEmployees, mapEmployeesToFilterOwners, mapEmployeesToRM } from "../../../common/helpers";
import useObject from "../../../hooks/useObject";
import DateRangeInput from "../../common/DateRangeInput";
import { useResetFilters } from "../../../hooks/useResetFilters";
import { SelectArrayInput } from "../../common/customDropdowns";
import LocationDropdown from '../../common/LocationDropdown/index.js';

const candidateFilters = [
  <TextInput label="Email" source="email" />,
  <TextInput source="city" />,
  <TextInput label="ID" source="id" />,
  <TextInput label="First Name" source="firstName" />,
  <TextInput label="Last Name" source="lastName" />,
  <TextInput source="phone" />,
  <TextInput source="keyword" />,
  <SelectArrayInput source="completion" isFilter choices={options.completionOpt} />,
  <SelectArrayInput source="status" isFilter choices={options.statusOpt} />,
  <SelectArrayInput source="jobSearchStatus" isFilter choices={options.jobOpt} label="Job Search Status" />,
  <TextInput source="country" />,
  <TextInput source="state" />,
  <LocationDropdown label="Physical Location" source ="physicalLocationGeo"/>,
  <LocationDropdown label="Preferred Location" source ="preferredLocationGeo"/>,
  <SelectArrayInput source="preferredWorkplaceType" isFilter choices={options.workTypeOpt} label="Preferred Workplace Type" />,
  <SelectArrayInput source="priorityCA" isFilter choices={options.priorityOpt} label="Priority CA" />,
  <TextInput label="Work Authorization" source="workAuthorized" />,
  <SelectArrayInput source="priorityRM" isFilter choices={options.priorityOpt} label="RM Priority" />,
  <SelectArrayInput source="preferredEmploymentType" isFilter choices={options.preferredEmpTypeOpt} label="Preferred Employment Type" />,
  <SelectArrayInput source="candidateType" isFilter choices={options.candidateTypeOpt} label="Candidate Type" />,
  <SelectArrayInput source="experienceWithSales" isFilter choices={options.salesExperienceOpt} label="Type of Sales Experience" />,
  <TextInput source="source" />,
  <SelectArrayInput source="videoQuality" isFilter choices={options.videoQualityOptions} />,
  <DateRangeInput source="dateAvailable" label="Date Available" />,
  <DateRangeInput source="timeCreated" label="Date Created" />,
  <DateRangeInput source="timeUpdated" label="Date Updated" />,
  <DateRangeInput source="education-endDate" label="Date Graduated" maxDate={new Date()} />,
];

const CandidateActions = ({ setIsFormOpen, isFormOpen, setReturnedObject }) => (
  <TopToolbar style={{ flexBasis: "100%", flexShrink: "0.8" }}>
    {!isFormOpen && <>
      <FilterButton />
      <CreateButton />
      <Button color="primary" onClick={() => { setIsFormOpen(true) }}><>Resume Upload</></Button>
    </>}
    {isFormOpen && <>
      <UploadForm {...{ setReturnedObject }} />
      <IconButton color="primary" size="small" onClick={() => { setIsFormOpen(false) }}><Close /></IconButton>
    </>}
  </TopToolbar>);

const CandidateList = props => {
  const navigate = useNavigate();
  const {pathname = ""} = useLocation();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const setReturnedObject = returnedObject => {
    navigate("create", { state: { record: returnedObject } });
  };
  const owners = useObject("employee");
  const getCAOwners = () => owners.map(mapEmployeesToFilterOwners);
  const getRMOwners = () => owners.filter(filterRMEmployees).map(mapEmployeesToRM);

  const ownersFilters = [
    <SelectArrayInput label="CA Owner" isFilter source="ownerCA.firstName" choices={getCAOwners()} />,
    <SelectArrayInput label="RM Owner" isFilter source="ownerRM" choices={getRMOwners()} />,
  ]

  useResetFilters('candidate');

  return (
    <List {...props}
      filters={[...candidateFilters, ...ownersFilters]}
      exporter={false}
      pagination={false}
      actions={<CandidateActions {...{ setIsFormOpen, isFormOpen, setReturnedObject }} />}
    >
      <Datagrid rowClick={(row) => window.open(`${pathname}/${row}/show`)} optimized isRowSelectable={() => false}>
        <TextField source="bullhornNumber" label="Id" />
        <EmailField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="_score" label="Relevance"/>
        <TextField source="status" />
        <TextField source="completion" />
      </Datagrid>
    </List>
  )
};

export default CandidateList