import { useEffect, useState } from "react";
import { useListContext } from "react-admin";
import { DateRange } from "react-date-range";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import FieldLabel from "../FieldLabel";
import { Container, DateInput, ClearButton, BoxBackground, DropdownBox } from "./styled";
import { getUTCDate, add24Hours } from "../../../common/helpers";

const DateRangeInput = ({ source, label, maxDate = undefined, minDate = undefined }) => {
  const { filterValues, setFilters } = useListContext();
  const [isToDate, setIsToDate] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    return () => {
      const newFilters = { ...filterValues };
      delete newFilters[source];

      setFilters(newFilters, {});
    }
  }, []);

  const handleDateRangeChange = ranges => {
    let { startDate, endDate } = ranges[source];
    setValue(ranges[source]);

    const isSameDay = isToDate && startDate.valueOf() === endDate.valueOf();

    if (isSameDay) {
      endDate = add24Hours(startDate);
    }

    const sDate = startDate?.toISOString()
    const eDate = endDate?.toISOString()

    setFromDate(getUTCDate(startDate).toISOString().substring(0, 10) || "");
    setToDate(getUTCDate(endDate).toISOString().substring(0, 10) || "");

    if (sDate !== eDate) {
      setFilters({
        ...filterValues,
        [source]: { gte: startDate.valueOf(), lte: endDate.valueOf() }
      });

      setOpen(false);
      setIsToDate(false);
    } else {
      setIsToDate(true);
    }
  };

  const handleDateClear = (ineq) => {
    const newValues = { ...filterValues };

    if (ineq === "gte") {
      setFromDate("");
      if (toDate) newValues[source] = { gte: new Date(toDate).valueOf() };
      else delete newValues[source];
    } else {
      setToDate("");
      if (toDate) newValues[source] = { gte: new Date(toDate).valueOf() };
      else delete newValues[source];
    }

    setFilters(newValues);
  };

  const clearOpenInput = () => {
    setFromDate("");
    setToDate("");
    setValue({ startDate: new Date(), endDate: null, key: source });
    setOpen(true)
    setIsToDate(false)
  };

  return (
    <div>
      <Container>
        <FieldLabel {...{ source, label }} />
        <div>
          <DateInput type="date" value={fromDate} readOnly onClick={clearOpenInput} />
          <ClearButton onClick={() => handleDateClear("gte")}>&times;</ClearButton>
          <span>{" to "}</span>
          <DateInput type="date" value={toDate} readOnly onClick={clearOpenInput} />
          <ClearButton onClick={() => handleDateClear("lte")}>&times;</ClearButton>
          {open && <>
            <BoxBackground onClick={() => setOpen(false)} />
            <DropdownBox>
              <DateRange
                maxDate={maxDate}
                minDate={minDate}
                shownDate={new Date()}
                moveRangeOnFirstSelection={false}
                ranges={[{ ...value, key: source }]}
                onChange={handleDateRangeChange}
                direction="vertical"
                scroll={{ enabled: true }}
                startDatePlaceholder="From"
                endDatePlaceholder="To"
              />
            </DropdownBox>
          </>}
        </div>
      </Container>
    </div>
  );
}

export default DateRangeInput;
