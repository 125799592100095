import {
  Create,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextInput,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { noteActionsOpt } from "../../../common/constants";
import useSendingUser from "../../../../hooks/useSendingUser";
import { SelectInput } from "../../../../components/common/customDropdowns";

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const SubmissionCreate = () => {
  const location = useLocation();
  const { candidate } = location?.state?.record || {};
  const sendingUser = useSendingUser();

  if (!candidate) {
    return <p>Error...</p>;
  }

  return (
    <Create
      resource={`candidate/${candidate.email}/note`}
      redirect={`/candidate/${candidate.email}/show/1`}
      transform={ data => ({
        author: sendingUser.fullName || sendingUser.email,
        ...data,
      })}
    >
      <SimpleForm toolbar={<EditToolbar />} warnWhenUnsavedChanges>
        <SelectInput fullWidth source="action" choices={noteActionsOpt} />
        <TextInput fullWidth source="comments" multiline />
      </SimpleForm>
    </Create>
  );
};

export default SubmissionCreate;
