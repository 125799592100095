import React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";

import Person from "@mui/icons-material/PersonOutline";
import City from "@mui/icons-material/LocationCity";
import Briefcase from "@mui/icons-material/BusinessCenterOutlined";
import Document from "@mui/icons-material/NoteAltOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Event from "@mui/icons-material/Event";

import DataProvider from "./components/DataProvider";
import CandidateList from "./components/candidate/list";
import CandidateShow from "./components/candidate/show";
import CandidateEdit from "./components/candidate/edit";
import CandidateCreate from "./components/candidate/create";
import ClientList from "./components/client/list";
import ClientShow from "./components/client/show";
import ClientEdit from "./components/client/edit";
import ClientCreate from "./components/client/create";
import CompanyView from "./components/client/CandidateView";
import RequisitionList from "./components/requisition/list";
import RequisitionShow from "./components/requisition/show";
import RequisitionEdit from "./components/requisition/edit";
import RequisitionCreate from "./components/requisition/create";
import OfferView from "./components/requisition/CandidateView";
import SubmissionList from "./components/submission/list";
import SubmissionShow from "./components/submission/show";
import SubmissionEdit from "./components/submission/edit";
import SubmissionCreate from "./components/submission/create";
import AuthProvider from "./components/AuthProvider";
import Login from "./Login";
import PlacementList from "./components/placement/list";
import PlacementShow from "./components/placement/show";
import AppointmentList from "./components/appointment/list";
import AppointmentEdit from "./components/appointment/edit";
import AppointmentCreate from "./components/appointment/create";
import AppointmentShow from "./components/appointment/show";
import UserCreate from "./components/user/create";
import NoteCreate from "./components/candidate/note/create";
import PlacementEdit from "./components/placement/edit";
import PlacementCreate from "./components/placement/create";
import ApprenticeshipsShow from "./components/apprentieceship/show";
import ApprenticeshipCreate from "./components/apprentieceship/create";
import ApprenticeshipEdit from "./components/apprentieceship/edit";

const App = () => (
  <Admin history={createHistory()} loginPage={Login} dataProvider={DataProvider} authProvider={AuthProvider}>
    <Resource
      name="candidate"
      list={CandidateList}
      show={CandidateShow}
      edit={CandidateEdit}
      create={CandidateCreate}
      icon={Person}
    />
    <Resource name="client" list={ClientList} show={ClientShow} edit={ClientEdit} create={ClientCreate} icon={City} />
    <Resource
      name="requisition"
      list={RequisitionList}
      show={RequisitionShow}
      edit={RequisitionEdit}
      create={RequisitionCreate}
      icon={Briefcase}
    />
    <Resource
      name="submission"
      list={SubmissionList}
      show={SubmissionShow}
      edit={SubmissionEdit}
      create={SubmissionCreate}
      icon={Document}
    />
    <Resource name="user" create={UserCreate} icon={Person} />
    <Resource name="note" icon={Document} create={NoteCreate} />
    <Resource
      name="appointment"
      create={AppointmentCreate}
      edit={AppointmentEdit}
      list={AppointmentList}
      show={AppointmentShow}
      icon={Event}
    />
    <Resource
      name="placement"
      list={PlacementList}
      show={PlacementShow}
      edit={PlacementEdit}
      create={PlacementCreate}
      icon={LocationOnIcon}
    />
    <Resource
      name="apprenticeship"
      show={ApprenticeshipsShow}
      create={ApprenticeshipCreate}
      edit={ApprenticeshipEdit}
    />
    <CustomRoutes>
      <Route path="/requisition/:id/candidate-view" element={<OfferView />} />
      <Route path="/client/:id/candidate-view" element={<CompanyView />} />
    </CustomRoutes>
  </Admin>
);

export default App;
