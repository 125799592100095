import { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
  margin-bottom: 20px;
  `;

const List = styled.ul`
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  max-height: 500px;
  overflow-y: auto;
  `;

const ListItem = styled.li`
  padding: 0.5rem;
  cursor: pointer;
  word-break: break-word;
  &:hover {
    color: white;
    font-weight: bold;
    background-color: #0a9aff;
  }
  `;

const SearchInput = styled.input`
    background-color:rgb(245,245,245);
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    font-size: 16px;
    color: #666;
    padding: 20px 0 5px 10px;

    &:focus {
      border-bottom: 2px solid #0098fc;
    }
`;

const FloatingLabel = styled.label`
  position: absolute;
  top: 15px;
  left: 10px;
  font-size: 16px;
  color: #666;
  
  transition: all 0.1s ease-out;
  pointer-events: none;
  background-color: transparent;
  z-index: 1;

  ${({ floating }) => floating && `
    transform: translateY(-15px);
    font-size: 12px;
    `}

  ${({ focused }) => focused && `
    color: #0098fc;
  `}
`;

const Autocomplete = ({
  onChange,
  onClose,
  onSelect,
  options,
  shouldShowSuggestions,
  placeholder = "Search...",
  initialValue = "",
  label = "",
}) => {
  const [focused, setFocused] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(shouldShowSuggestions);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const parsedOptions = options.map((option) => {
    if (typeof option === 'string') {
      return { label: option, value: option };
    }

    return option;
  });

  const handleInputChange = (evt) => {
    const newValue = evt.target.value;
    onChange(newValue);
    setShowSuggestions(shouldShowSuggestions || !!newValue);
    setInputValue(newValue);
  };

  const handleSuggestionClick = (label, title) => {
    setShowSuggestions(false);
    setInputValue(label);
    onSelect({ target: { value: title } });
    onClose && onClose();
  };

  return (
    <Container onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
      <FloatingLabel floating={!!inputValue || focused} focused={focused}>{label}</FloatingLabel>
      <SearchInput
        type="text"
        placeholder={focused ? placeholder : ""}
        value={inputValue}
        onChange={handleInputChange}
      />
      {showSuggestions && options.length > 0 && (
        <List>
          {parsedOptions.map((option, idx) => (
            <ListItem key={idx} onClick={() => handleSuggestionClick(option.label, option.value)} id={`opt_${option.value} `}>
              {option.label}
            </ListItem>
          )
          )}
        </List>
      )}
    </Container>
  );
};

export default Autocomplete;