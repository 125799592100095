import { Show, SimpleShowLayout, TextField, TopToolbar, EditButton, FunctionField, DateField } from 'react-admin';
import { RenderCandidateLink, RenderClientLink } from '../../common/helpers';
import { CompletedActions } from '../components/CompletedActions';

const Actions = () => (
  <TopToolbar>
    <EditButton />
  </TopToolbar>
);

const ApprenticeshipsShow = () => (
  <Show actions={<Actions />}>
    <SimpleShowLayout>
      <FunctionField
        source="candidate"
        render={RenderCandidateLink}
      />
      <FunctionField
        source="client"
        render={RenderClientLink}
      />
      <TextField source="status" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      <FunctionField
        source="completedActions"
        render={CompletedActions}
      />
    </SimpleShowLayout>
  </Show>
);

export default ApprenticeshipsShow;