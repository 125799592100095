import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useObjectByParam } from "../../hooks/useObject";

export const mimeTypes = {
  "image/bmp": "bmp",
  "image/gif": "gif",
  "image/tiff": "tif",
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/svg+xml": "svg",
  "image/webp": "webp",
};

export const removePTag = (text) => {
  const tempTxt = text.trim();
  if (tempTxt.slice(0, 3) === "<p>" && tempTxt.slice(-4) === "</p>") {
    return tempTxt.slice(3, -4);
  } else {
    return text;
  }
};

export const isValidSocialLink = (socialMedia) => (value) => {
  const SocialMediaRegex = {
    linkedin: /^https:\/\/www\.linkedin\.com\/(in|company|school)\/[a-zA-Z0-9]+/,
    twitter: /^https:\/\/www\.twitter\.com\/[a-zA-Z0-9]+/,
    instagram: /^https:\/\/www\.instagram\.com\/[a-zA-Z0-9]+/,
  };

  const regex = SocialMediaRegex[socialMedia];
  if (!regex) return "Invalid social media";

  if (!value || regex.test(value)) return undefined;
  return `Invalid ${socialMedia} link`;
};

export const RenderCandidateLink = ({ candidate }) => {
  if (!candidate) return null;
  if (!candidate.email)
    return `${candidate.id}: ${candidate.firstName} ${candidate.lastName}`;

  return (
    <Button
      component={Link}
      to={{ pathname: `/candidate/${candidate.email}/show` }}
      sx={{ padding: 0, textTransform: "unset" }}
    >
      {candidate.id}: {candidate.firstName} {candidate.lastName} {" - "}
      {candidate.email}
    </Button>
  );
};

export const RenderClientLink = ({ client }) =>
  client ? (
    <Button
      component={Link}
      to={{ pathname: `/client/${client.id}/show` }}
      sx={{ padding: 0, textTransform: "unset" }}
    >
      {client.id}: {client.name}
    </Button>
  ) : null;

export const RenderRequisitionPhrase = ({ requisition }) => {
  const fullRequisition = useObjectByParam("requisition", {
    id: requisition?.id,
  });

  return (
    <Button
      component={Link}
      to={{ pathname: `/requisition/${fullRequisition.id}/show` }}
      sx={{ padding: 0, textTransform: "unset" }}
    >
      {fullRequisition.id}: {fullRequisition.title} {" - "}
      {fullRequisition.client?.name}
    </Button>
  );
};

export const getContentType = imageData => imageData.split(";")[0].split(":")[1].toLowerCase();
