import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  DateField,
} from "react-admin";

const ApprenticeshipsView = ({ clientId }) => {
  return (
    <List
      pagination={false}
      resource={`client/${clientId}/apprenticeship`}
      title={""}
      empty={<div>No apprenticeships found</div>}
      exporter={false}
    >
      <Datagrid
        rowClick={(id) => `/apprenticeship/${id}/show`}
        isRowSelectable={() => false}
      >
        <TextField source="status" />
        <DateField source="startDate" />
        <DateField source="endDate" />
        <FunctionField source="completedActions" render={({ completedActions = [] }) => completedActions.map(({type}) => type).join(", ")} />
      </Datagrid>
    </List>
  );
};

export default ApprenticeshipsView;
