import { Show, SimpleShowLayout, DateField, TextField, FunctionField, TopToolbar, EditButton } from 'react-admin';
import { RenderCandidateLink, RenderClientLink, RenderRequisitionPhrase } from '../../common/helpers';

const PlacementActions = () => (
  <TopToolbar>
      <EditButton />
  </TopToolbar>
);

const PlacementShow = () => (
  <Show actions={<PlacementActions />}>
    <SimpleShowLayout>
    <TextField fullWidth source="id" />
      <TextField fullWidth source="apprenticeshipCoach" />
      <TextField fullWidth source="apprenticeshipStatus" />
      <FunctionField
        source="candidate"
        render={RenderCandidateLink}
      />
      <FunctionField
        source="client"
        render={RenderClientLink}
      />
      <DateField fullWidth source="dateEndActual" label="Actual End Date" />
      <DateField fullWidth source="datePlacement" label="Date Placement" />
      <DateField fullWidth source="dateStart" label="Start Date" />
      <TextField fullWidth source="employmentType" label="Employment Type" />
      <TextField fullWidth source="lastUpdateSource" label="Last Update Source" />
      <FunctionField
        source="owner"
        render={({ owner }) => owner ? `${owner.id}: ${owner.firstName} ${owner.lastName}` : null}
      />
      <FunctionField label="Requisition" render={RenderRequisitionPhrase} />
      <TextField fullWidth source="status" />
      <DateField fullWidth source="timeCreated" label="Time Created" />
      <DateField fullWidth source="timeUpdated" label="Time Updated" />
    </SimpleShowLayout>
  </Show>
);

export default PlacementShow;