import { Create, DateTimeInput, TextInput, SimpleForm, Toolbar, SaveButton, LoadingPage, required } from "react-admin";
import { mapCandidatesToOptions, mapEmployeesToOwners, mapRequisitionsToOptions } from "../../../common/helpers";
import { commMethodsOpt, apptTypeOpt } from "../../common/constants";
import useObject from "../../../hooks/useObject";
import useSendingUser from "../../../hooks/useSendingUser";
import { ChoicesWrapper, AutocompleteInput } from "../../common/customDropdowns";

const EditToolbar = props => <Toolbar {...props} ><SaveButton /></Toolbar>;

const AppointmentCreate = () => {
  const owners = useObject("employee");
  const { id: defaultOwner, loading } = useSendingUser();

  if (loading) return <LoadingPage />;
  
  return (
    <Create
      redirect="show"
      transform={({ candidate, ...data }) => ({
        ...data,
        clientContactName: "Tyler",
        candidate: { id: candidate?.bullhornNumber || candidate?.id },
        dateBegin: Date.parse(data?.dateBegin),
        dateEnd: Date.parse(data?.dateEnd)
      })
      }

    >
      <SimpleForm toolbar={<EditToolbar />} defaultValues={{
        owner: { id: defaultOwner }
      }}>
        <ChoicesWrapper source="candidate" choices={[]} dataMapper={mapCandidatesToOptions}>
          <AutocompleteInput fullWidth disabled />
        </ChoicesWrapper>
        <AutocompleteInput fullWidth source="communicationMethod" choices={commMethodsOpt} label="Communication Method" />
        <DateTimeInput fullWidth source="dateBegin" label="Date Begin" validate={required()} />
        <DateTimeInput fullWidth source="dateEnd" label="Date End" validate={required()} />
        <TextInput fullWidth source="description" />
        <ChoicesWrapper source="owner" choices={owners} dataMapper={mapEmployeesToOwners}>
          <AutocompleteInput fullWidth validate={required()} />
        </ChoicesWrapper>
        <ChoicesWrapper source="requisition" choices={[]} dataMapper={mapRequisitionsToOptions}>
          <AutocompleteInput fullWidth disabled />
        </ChoicesWrapper>
        <TextInput fullWidth source="subject" />
        <AutocompleteInput fullWidth source="type" choices={apptTypeOpt} />
        <TextInput fullWidth source="location" label="Location / Phone / Link" />
      </SimpleForm>
    </Create>
  )
};

export default AppointmentCreate;
