export const skillsOpt = [
  "Strong work ethic",
  "Sales experience",
  "Strong education background",
  "Eager to learn",
  "Strong work history",
  "Excellent communication skills",
];

export const statusOpt = [
  "New / Unqualified",
  "CA - Scheduled",
  "CA - Rescheduled",
  "CA - No Show",
  "CA - Online Video Submission",
  "CA - Journey",
  "CA - Maybe - Nurture/Potential",
  "CA - Maybe - Sent to Video",
  "CA - YES - Bad Timing",
  "CA - YES - Geo Restriction",
  "CA - YES - Intro to RM sent",
  "CA - YES - Gone Dark",
  "CA - YES - OPT/Visa Required",
  "CA - No - Rejected",
  " CA - College Student/Nurture",
  "Mkt Lead",
  "LinkedIn Application",
  "RM- No answer",
  "RM - YES- prepping for interviews",
  "RM - YES- ready to interview",
  "RM YES- final stages",
  "RM - YES- not ready to interview (timing)",
  "RM - Maybe - still evaluating",
  "RM - No - Rejected",
  "Overqualified",
  "CC Cancelled/Rescheduled",
  "CC Didn't Answer",
  "CC No - Sent to Online Bootcamp",
  "CC Maybe - Still screening for TM",
  "CC Yes - not ready to interview (timing)",
  "CC Yes - geo restriction",
  "CC Yes - Gone Dark",
  "CC Yes - Intro to TM SENT",
  "TM- no answer,TM YES - Prepping for Interviews",
  "TM YES - READY TO INTERVIEW",
  "TM YES - FINAL STAGES",
  "TM Yes - not ready to interview (timing)",
  "TM Yes - geo restriction",
  "TM Yes - gone dark",
  "TM Maybe - Sent to online bootcamp",
  "TM No - Rejected and sent to online bootcamp",
  "PLACED",
  "Found Job On Own",
  "Nurture / College Student",
  "Not interested",
  "CC Yes- OPT",
  "Internal candidate",
  "New- Handshake resume upload",
  "New- career fair resume upload",
  "Gone Dark",
  "no current opps",
  "intern candidate",
  "Imported",
  "Group Interview Prep Session Completed",
  "Mock Interview Completed",
  "Sales and Interview Bootcamps Completed,CA - Marketing Recycle",
];

export const jobOpt = [
  "Currently in school",
  "Currently unemployed & looking for new opportunities",
  "Currently employed & looking for new opportunities",
  "Just here to learn and poke around",
];

export const locationOpt = [
  "San Francisco, CA",
  "San Jose, CA",
  "New York, NY",
  "Los Angeles, CA",
  "San Diego, CA",
  "Chicago, IL",
  " Remote",
  "Boston, MA",
  "Dallas, TX",
  "Austin, TX",
  "Phoenix, AZ",
  "Nashville, TN",
  "Denver, CO",
  "Irvine, CA",
  "Seattle, WA",
  "Atlanta, GA",
];

export const workTypeOpt = [
  "Remote",
  "Hybrid",
  "On-site",
];

export const authorizedOpt = [
  "Yes",
  "No",
  "Yes - will require sponsorship now or in the future",
];

export const eduStatusOpt = [
  "Graduated",
  "In Progress",
  "Incomplete",
];

export const empRangesOpt = [
  "10 - 50",
  "51 - 200",
  "201 - 500",
  "501 - 1000",
  "1001 - 5000",
  "5001 - 10000",
  "10001 +",
];

export const fundingStageOpt = [
  "Pre-seed",
  "Seed",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E+",
  "Public",
  "Private",
];

export const employmentTypeOpt = [
  "Full Time",
  "Part Time",
  "Internship",
  "Contractor",
];

export const requisitionOpt = [
  "Accepting Candidates",
  "Offer Out",
  "Placed",
  "On Hold",
  "Lost",
  "Archive",
];

// export const placementStatusOpt = [
//   "New / Unqualified",
//   "CA - Scheduled",
//   "CA - Rescheduled",
//   "CA - No Show",
//   "CA - Online Video Submission",
//   "CA - Journey",
//   "CA - Maybe - Nurture/Potential",
//   "CA - Maybe - Sent to Video",
//   "CA - YES - Bad Timing",
//   "CA - YES - Geo Restriction",
//   "CA - YES - Intro to RM sent",
//   "CA - YES - Gone Dark",
//   "CA - YES - OPT/Visa Required",
//   "CA - No - Rejected",
//   "CA - College Student/Nurture",
//   "Mkt Lead, LinkedIn Application",
//   "RM- No answer",
//   "RM - YES- prepping for interviews",
//   "RM - YES- ready to interview",
//   "RM YES- final stages",
//   "RM - YES- not ready to interview (timing)",
//   "RM - Maybe - still evaluating",
//   "RM - No - Rejected",
//   "Overqualified",
//   "CC Cancelled/Rescheduled",
//   "CC Didn't Answer",
//   "CC No - Sent to Online Bootcamp",
//   "CC Maybe - Still screening for TM",
//   "CC Yes - not ready to interview (timing)",
//   "CC Yes - geo restriction",
//   "CC Yes - Gone Dark",
//   "CC Yes - Intro to TM SENT",
//   "TM- no answer",
//   "TM YES - Prepping for Interviews",
//   "TM YES - READY TO INTERVIEW",
//   "TM YES - FINAL STAGES",
//   "TM Yes - not ready to interview (timing)",
//   "TM Yes - geo restriction",
//   "TM Yes - gone dark",
//   "TM Maybe - Sent to online bootcamp",
//   "TM No - Rejected and sent to online bootcamp",
//   "PLACED",
//   "Found Job On Own",
//   "Nurture / College Student",
//   "Not interested",
//   "CC Yes- OPT",
//   "Internal candidate",
//   "New- Handshake resume upload",
//   "New- career fair resume upload",
//   "Gona Dark",
//   "no current opps",
//   "intern candidate",
//   "Imported",
//   "Group Interview Prep Session Completed",
//   "Mock Interview Completed",
//   "Sales and Interview Bootcamps Completed",
//   "CA - Marketing Recycle",
// ];

export const placementStatusOpt = [
  "Submitted",
  "Approved",
  "Pulled Out",
  "Offer Rescinded",
];

export const placementEmploymentType = [
  "Vendition Pay",
  "Partner Pay",
];

export const placementApprenticeshipCoach = [
  "Eddie",
  "Marianna",
  "Tyler L",
  "Nick",
  "Michelle",
];

export const placementApprenticeshipStatus = [
  "Current Apprentice",
  "Didn't finish",
  "Graduated- converted at sponsor",
  "Graduated- working",
  "Graduated- not working",
  "Didn't start",
  "Unique case",
];

export const priorityOpt = [
  "NA",
  "P1 plus",
  "P1",
  "P2 plus",
  "P2",
  "P3",
  "P4",
];

export const sourceOpt = [
  "Linkedin Ads",
  "LinkedIn Job Application",
  "Career Fair",
  "Glassdoor",
  "AngelList",
  "Online Application Form",
  "Employee Referral",
  "External Referral",
  "SF Event -- ESF",
  "Contact Us Form",
  "Newsletter",
  "Handshake",
  "Indeed.com",
  "Zip Recruiter",
  "Teachable",
  "Internal Referral",
  "Inbound call",
  "LinkedIn InMail Ads",
  "Facebook - Instagram Ads",
  "TikTok Lead Ads",
  "LinkedIn Lead Ads",
  "Meta Lead Ads",
  "candidate_app_default",
  "Webinar",
  "Alumni Referral",
  "LinkedIn InMail",
];

export const preferredEmpTypeOpt = [
  "Direct Hire",
  "Fellowship",
];

export const ratingOpt = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
];

export const videoQualityOptions = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10
];

export const salesExperienceOpt = [
  "SaaS",
  "Sales Development",
  "Account Executive",
  "Cold Calling",
  "Door to Door",
  "Retail Sales",
  "Non-technical Sales",
];

export const reasonNotInterestedOpt = [
  "Overqualified",
  "Money",
  "Doesn't want to be in sales",
  "Location",
  "Benefits",
  "Found Job on Own",
  "NA",
];

export const submisionStatusOpt = [
  "Internally Submitted",
  "Sales Rep Rejected",
  "Resume/LI sent",
  "Phone Screen",
  "Candidate Not Interested",
  "Client Rejected",
  "Offer Extended",
  "Offer Rejected",
  "Placement",
  "Candidate Interested",
  "Onsite",
  "2nd onsite",
  "Assignment",
  "References",
  "Post-onsite call",
  "Resume Approved",
];

export const clientStatusOpt = [
  "Active Account",
  "Passive Account",
  "Inactive",
  "Archive",
];

export const reqPriorityOpt = [
  { id: 1, name: "10%" },
  { id: 2, name: "20%" },
  { id: 3, name: "40%" },
  { id: 4, name: "60%" },
  { id: 5, name: "80%" },
];

export const resumeReviewOpt = [
  "Required",
  "Not Required",
];

export const interviewDurationOpt = [
  { id: 15, name: "15 minutes" },
  { id: 30, name: "30 minutes" },
  { id: 45, name: "45 minutes" },
  { id: 60, name: "60 minutes" },
];

const SCHEDULING_OPTIONS = [
  `I will provide a scheduling link (Calendly, Hubspot, etc)`,
  "Ask Vendition to coordinate interviews",
];

export const interviewSchedulingMethodOpt = [
  { id: SCHEDULING_OPTIONS[0], name: SCHEDULING_OPTIONS[0] },
  { id: SCHEDULING_OPTIONS[1], name: SCHEDULING_OPTIONS[1] },
];
export const commMethodsOpt = [
  "Phone",
  "Onsite Appointment",
  "Offsite Appointment",
];

export const apptTypeOpt = [
  "Interview",
  "Phone Screen",
  "2nd Phone Screen",
  "GCal meeting",
  "Meeting",
];

export const noteActionsOpt = [
  "Prescreen",
  "Intro to RM sent",
  "First call with RM",
  "Prepping for interviews",
  "Ready to Interview",
  "1:1 Mock Interview",
  "Group Interview Coaching",
  "Company Based Interview Prep",
  "CA Priority",
  "RM Priority",
  "Other",
];

export const completionOpt = [
  { id: "contact", name: "Contact" },
  { id: "basic", name: "Basic" },
  { id: "intermediate", name: "Intermediate" },
  { id: "complete", name: "Complete" },
];

export const candidateTypeOpt = [
  "Recent College Grad",
  "Recent Veteran",
  "Career Switcher - Education",
  "Career Switcher - Hospitality",
  "Career Switcher - Healthcare",
  "Career Switcher - Customer Service",
  "Career Switcher - Sales",
  "Career Switcher - Finance",
  "Career Switcher - Insurance",
  "Career Switcher - Science",
  "Career Switcher - Technical",
  "Career Switcher - Other",
];

export const vSatList = [
  "Responsibility Management",
  "Leadership",
  "Time Management",
  "Information Processing",
  "Independence",
  "Stress Regulation",
  "Adaptability",
  "Creativity",
  "Optimism",
  "Organizational Ability",
  "Goal Regulation",
  "Persuasiveness",
  "Conversational Ability",
  "Teamwork",
  "Decision-Making",
  "Capacity for Consistency",
];

export const vSatValuesOpt = [
  { id: 0, name: "Less Important" },
  { id: 3, name: "More Important" },
  { id: 5, name: "Most Important" },
];

export const completedActionTypes = [
  "Online Learning",
  "Mentorship Session with Vendition",
  "Mentorship Session with Direct Manager",
  "Live Cohort Coaching Session",
]