import styled from "styled-components";

export const Photo = styled.div`
  width: min(50vh, 50vw);
  height: min(50vh, 50vw);
  margin-right: 1rem;
  margin-bottom: 1rem;
  background-size: cover;
  border: 1px solid gray;
  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const PhotosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
`
