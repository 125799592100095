import {
  Create,
  SimpleForm,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  TextInput,
  email,
  required,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { mapClientToOptions } from "../../../common/helpers";
import { ChoicesWrapper } from "../../../components/common/customDropdowns";

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const SubmissionCreate = () => {
  const location = useLocation();
  const { client } = location?.state?.record || {};

  if (!client) {
    return <p>Error...</p>;
  }

  return (
    <Create
      resource={`client/${client.id}/user`}
      redirect={`/client/${client.id}/show/3`}
      transform={({ client, ...data }) => data }
    >
      <SimpleForm
        toolbar={<EditToolbar />}
        warnWhenUnsavedChanges
        defaultValue={{ client }}
      >
        <ChoicesWrapper
          source="client"
          choices={[]}
          dataMapper={mapClientToOptions}
        >
          <AutocompleteInput fullWidth disabled />
        </ChoicesWrapper>
        <TextInput fullWidth source="firstName" validate={[required()]} />
        <TextInput fullWidth source="lastName" validate={[required()]} />
        <TextInput fullWidth source="email" validate={[required(), email()]}/>
      </SimpleForm>
    </Create>
  );
};

export default SubmissionCreate;
